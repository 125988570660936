import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {TokensService} from './tokens.service';
import {BaseService, ResponseInterceptor, ServiceBuilder} from 'ts-retrofit';
import {GenericResponse} from '../data/riesko/generic-response';
import {environment} from '../../environments/environment';
import {RieskoErrorNotificationService} from './riesko-error-notification.service';

export const ACCEPT_JSON = {'Accept': 'application/json'};
export const CONTENT_TYPE_JSON = {'Content-Type': 'application/json'};
const CRSF_TOKEN = 'CSRF_Token';

// ===================================================================================
//    FACTORY FUNCTION
// ===================================================================================

export function createRestApiService<T extends BaseService>(translate: TranslateService,
                                                            storage: StorageService,
                                                            router: Router,
                                                            tokensService: TokensService,
                                                            rieskoErrorNotificationService: RieskoErrorNotificationService,
                                                            type: new (builder: ServiceBuilder) => T): T {

  class RieskoResponseInterceptor extends ResponseInterceptor<any> {
    api: T;

    onFulfilled(r) {
      const json = JSON.stringify(r);

      if (r.data != null) {
        const dataResponse = r.data as GenericResponse<any>;
        if (dataResponse != null && dataResponse.bSuccess === false) {
          // errore rilevato
          let exceptionTxt;
          if (dataResponse.iError === -1) {
            if (dataResponse.sError != null && dataResponse.sError.length > 0) {
              exceptionTxt = dataResponse.sError;
            }
          }
          if (dataResponse.iError > 0) {
            const translationKey = `errors.${dataResponse.iError}`;
            exceptionTxt = translate.instant(translationKey);
            if (exceptionTxt === translationKey) {
              // traduzione non implementata: riporto il messaggio di errore riportato
              if (dataResponse.sError != null && dataResponse.sError.length > 0) {
                exceptionTxt = dataResponse.sError + ` (code: ${dataResponse.iError})`;
              }
            }
          }

          if (exceptionTxt == null || exceptionTxt.length === 0) {
            exceptionTxt = translate.instant(`errors.generic`);
          }
          try {
            if (dataResponse.iError !== 3) {
              const exceptionStack = dataResponse.stack?.join('\n')?.replace('\t', '');
              rieskoErrorNotificationService.emitError.emit({title: exceptionTxt, body: exceptionStack, code: dataResponse.iError});
            }
          } catch (ex) {

          }
          throw Error(exceptionTxt);
        }
        if (dataResponse?.CSRF_Seed != null) {
          tokensService.setCrsfSeed(dataResponse.CSRF_Seed);
        }
        if (r.headers.authentication) {
          const token = r.headers.authentication;
          tokensService.setJwt(token);
        }
      }
      return r;
    }

    onRejected(error: any) {
      const checkErrorCode = (code: string) => error.message.includes(code);
      if (checkErrorCode('401')) {
        console.log('errore 401 rilevato');
        storage.doUrlScreenshot();
      }
      throw new RestApiError('Non sei autorizzato ad accedere alla risorsa, ricollegati'); // TODO: traduzione del messaggio
      // return error;
    }

  }

  const interceptor = new RieskoResponseInterceptor();
  const api = new ServiceBuilder()
    .setRequestInterceptors((request) => {
      const url = request.url.substr((environment.apiUrl + environment.basePrefixApiUrl).length);
      if (!isInIgnoreCrsfList(url)) {
        if (tokensService.hasCrsfSeed()) {
          request.headers[CRSF_TOKEN] = tokensService.getCrsfToken(url);
        }
        if (tokensService.hasJwt()) {
          request.headers['Authentication'] = tokensService.getJwt();
        }

      }
      if (request.data != null) {
        /*const entities = new Entities();
        const str = JSON.stringify(request.data);
        request.data = entities.encode(str);
a
        /*
        const stack = [];
        for (const prop in request.data) {
          if (prop instanceof Object) {
            stack.push(prop);
          }

          if (prop instanceof String) {
            prop = entities.encode(prop);
          }
        }
        */
        // TODO: chiedere a WITTO. Possiamo iterare su tutte le props dell'oggetto e applicare la encode in auto oppure gestirla a manina prima delle chiamate
        // PRO/CONTRO: auto e` piu` veloce da implementare e da gestire. Tendenzialmente meno bug.
        // request.data = entities.encode(request.data);
      }
      return request;
    })
    .setResponseInterceptors(interceptor)
    .build(type);
  interceptor.api = api;

  return api;
}

// ===================================================================================
//    CRSF IGNORE LIST
// ===================================================================================


const ignoreList = ['/login'];

function isInIgnoreCrsfList(apiUrl: string) {
  return ignoreList.includes(apiUrl);
}

// ===================================================================================
//    ERROR MANAGER
// ===================================================================================

declare global {
  interface Error {
    name: string;
    message: string;
    stack?: string;
  }
}

export class RestApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RestApiError';
    this.message = message;
  }
}
