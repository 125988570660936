<div class="container-fluid p-4" *ngIf="risk">
  <!-- Title -->
  <div class="row">
    <div class="col-10">
      <h3><i class="iconsminds-cloud-weather"></i> {{ risk.code }}</h3>
    </div>
    <div class="col-2 text-right">
      <button class="btn btn-outline-primary btn-xs" (click)="open()">
        <!-- eye icon -->
        <i class="fa fa-eye"></i>
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="row">
    <div class="col-12">
      <tabset>
        <tab [heading]="'risks.popup-title' | translate ">
          <app-risk-general-info [risk]="risk"></app-risk-general-info>
        </tab>
        <tab [heading]="'risks.popup-risk-category'|translate">
          <app-risk-category-info [risk]="risk"></app-risk-category-info>
        </tab>
        <tab [heading]="'risks.popup-cause-effects'|translate">
          <app-risk-causes-effects-info [risk]="risk"></app-risk-causes-effects-info>
        </tab>
        <tab [heading]="'risks.popup-attachments-list'|translate">
          <app-risk-attachments-info [attachContainer]="risk"></app-risk-attachments-info>
        </tab>
        <tab [heading]="('risks.popup-evaluated-in' | translate ) + ' ' + getRiskValutatedAtTabTitle()">
          <app-risk-valutated-info [risk]="risk"></app-risk-valutated-info>
        </tab>
      </tabset>
    </div>
  </div>

</div>
