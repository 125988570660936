<a class="btn btn-sm btn-outline-primary view-file" [style]="getCustomStyle()"
   (click)="openSecureFile()"
   target="_blank" *ngIf="!disableDownload">
  <img [src]="getIconFileType()"
       (error)="getIconFileTypeOnError(iconImageFileType)" #iconImageFileType>
  {{filename}}
</a>

<span *ngIf="disableDownload">
  <img [src]="getIconFileType()"
       (error)="getIconFileTypeOnError(iconImageFileType)" #iconImageFileType>
  {{filename}}
</span>

<ng-template [ngIf]="!onlyPreview">
  <button class="btn btn-primary" (click)="downloadSecureFile()">
    <span class="glyph-icon simple-icon-cloud-download"></span>
  </button>
</ng-template>
