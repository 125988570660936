import {UserInfoTopicMessage} from './info-topic-message';
import {Risk} from '../risk';
import {VAttachment} from './v-item';
import {Planning, PlanningAssociation} from './planning';
import {GeneralData} from '../treatment';

export interface BiaProcessStatus {
  cod: BiaProcessStatusCode;
  dis: string;  // Descrizione tradotta
  selected?: boolean;
}

export enum BiaProcessStatusCode {
  Bozza = 0,
  Compilazione = 1,
  Attivo = 2
}

export interface BiaItem {
  id: number;
  sItem: string;      // yyy_xxxxxxxxxxxx
  sItemType: string;  // yyy
  attachments?: any;
}

export interface BiaUser extends UserInfoTopicMessage {
  idUser?: number;
  tel?: string;
  lang?: string;
  email?: string;
}

export interface BiaProcessHeader extends BiaItem {
  childs?: Array<BiaProcess>;
  parents?: Array<BiaProcess>;
  path: string;       // 1, 1.1, 1.1.1
  livello: BiaProcessLevel;
  monitors?: BiaMonitor[];
  title: string;
  priority: number;   // = ad ultimo char di path
  error?: string;     // eventuale errore, vedi ...
  status: BiaProcessStatus;
  referer: BiaUser;
  impatti: Array<BiaSelectData>;
  impatto: Array<BiaImpactLevel>;
  impatto_value: number;
  compilation: BiaCompilation;
  isService?: boolean;
}

export interface BiaMonitor {
  id: number;
  ownerLabel: string;
  level: 'warning' | 'danger' | 'idle';
  type?: 'survey' | 'module' | 'form'; // non conosco le altre tipologie, ma presumo che per ora non mi serva conoscerle
  status: { val: number, selected: boolean, lab?: string }[];
  pending?: boolean;
  date?: GeneralData;
}

export enum BiaProcessLevel {
  SERVICE = 0,
  PROCESS = 1,
  SUBPROCESS = 2,
  ACTIVITY = 3
}

export interface BiaSelectData {
  cod: number;
  lab: string;  // Descrizione tradotta
  tag?: string;
  factor?: number;
  selected?: boolean;
}

export interface BiaSelectDataLbl {
  cod: number;
  lbl: string;  // TODO: WITTO - possiamo usare lab? cosi evito di fare interfaccie extra...
  selected?: boolean;
}

export interface BiaProcess extends BiaProcessHeader {
  subImpatti: boolean;
  allowSub: number; // 0-no sub; 1-Solo livello+1; 2 - Solo livello+2
  desc: string; // TODO: Mancava, è corretto?
  plans: PlanningAssociation[];
}

export interface BiaProcessPost extends BiaProcess {
  internal: boolean; // Se non internal, si attiva Supplier
  backup: boolean;
  bnorm: boolean;   // Abilita norm
  norm: BiaNormative[];     // RTF list
  sede?: string;    // RTF
  edifici?: string; // RTF
  utility?: string; // RTF
  trasporti?: string; // RTF
  priority_restore?: number;
}

export interface BiaSupplierActivity extends BiaItem {
  activity: string;
  detail: string;
}

export interface BiaSite {
  id: number;
  sItem: string;
  cap: string;
  citta: string;
  descrizione: string;
  nomenclatura: string;
  paese: string;
  unita: string;
  via: string;
}

export interface BiaSupplies {
  id: number;
  sItem: string;
  sItemType: string;
  name: string;
}

export interface BiaSupplier extends BiaItem {
  name: string;
  activities?: Array<BiaSupplierActivity>;
  cf: string;
  nation: string;
  sItemType: string;
  indirizzo: string;
  provincia: string;
  pec: string;
  piva: string;
  sItem: string;
  site: string;
  cap: string;
  id: number;
  email: string;
  citta: string;
}

export interface BiaResource extends BiaItem {
  name: string;
  surname: string;
  competenza: string;
  tel: string;
  mob: string;
  email: string;
  tel_pri: string;
  mob_pri: string;
  email_pri: string;
  work_remote: boolean;
  internal?: boolean;  // default: true - se false supplier presente
  supplier: BiaSupplier;
}


export interface BiaCompileState {
  cod: number;
  lbl: string;  // Descrizione tradotta
  selected?: boolean;
  success?: boolean;
}

export enum BiaCompilationStateEnum {
  notCompiled = 0,
  compiled = 1,
}

export interface BiaCompilable {
  stato: BiaCompileState[];
  owner?: BiaProcess;
  note?: string;
  id: number;
}

export interface BiaProcessSupplier extends BiaCompilable {
  blocking: boolean;
  scorta_ut: BiaSelectData[];
  scorta_qty: number;
  supplier: BiaSupplier;
  supply: BiaSupplies;
  activity?: BiaSupplierActivity;
  referer: BiaResource;
  freq_ut: BiaSelectData[];
  freq_qty: number;
  saturazione: number;
  aumento: number;
  impatto: number;
  supply_pri: BiaSelectData[]; // provvisorio
  supplier_name: string; // provvisorio
  supply_group: string; // provvisorio
  proc?: { id: number, path: string, title: string }; // usato solo per la chiamata /table
}

export interface BiaImpactLevel {
  id: number;       // id
  val?: number;    // valore per risk Mng
  dis?: string;      // descrizione // TODO: dis e non des?
  importo?: number;  // valore di riferimento
  perc?: number;     // percentuale
  classMarker?: string; // classe CSS da associare: risk-gradient-0 / risk-gradient-10 - vanno dal verde al rosso passando per l'arancio
  selected?: boolean;
}

export interface BiaCutOff extends BiaCompilable {
  periodNum?: number;
  periodUm: { cod: number, lab: string, selected: boolean }[];
  // orarioUm: { cod: number, lab: string, selected: boolean }[];
  // orarioNum?: number;
  orarioTi?: string; // 5 char. formato: HH:mm
  cons?: string;
  consFreq?: BiaSelectData[];
  consVal?: number;
  cons_note: string;
  tipo: BiaSelectData[]; // Cutoff, Peak
  desc: string;
  proc?: { id: number, path: string, title: string }; // usato solo per la chiamata /table
}

export interface BiaImpact extends BiaCompilable {
  tipo?: BiaSelectData; // NORMATIVO, OPERATIVO,...
  impatti: Array<BiaImpactLevel>;  // Da A ad I
  class: string;  // Da A ad I primo impatto con classMarker
  categories?: { reference: number, code: string, label1: string, label2: string }[];
  mtpodUM: BiaSelectData[];
  mtpodNum: number;
  rpoUM: BiaSelectData[];
  rpoNum: number;
  proc?: { id: number, path: string, title: string };
  warnings?: {
    MTPD?: {
      top?: {
        max?: number;
        siblings?: number;
      };
      bottom?: {
        childs: number;
      }
    },
    RTO?: {
      top?: {
        max?: number;
        siblings?: number;
      };
      bottom?: {
        childs: number;
      }
    }
  };
}

export interface BiaInterdip extends BiaCompilable {
  period: string;
  blocking: boolean;
  blocking_qty: number;
  blocking_um: Array<BiaSelectData>;
  type: Array<BiaSelectData>;
  target?: BiaProcessHeader;
  input: boolean;
  non_critic: string;
  desc: string;
}

export interface BiaImpactWrapper {
  type: BiaSelectData;  // NORMATIVO, OPERATIVO,...
  impacts: Array<BiaImpact>;
}

export interface BiaVolumi extends BiaCompilable {
  num_op: number;
  periodQty: number;
  periodUm: Array<BiaSelectData>;
  importo: number;
  proc?: { id: number, path: string, title: string }; // usato solo per la chiamata /table
}

export interface BiaResourceSlot {
  emerg: BiaResource;
  substitList: Array<BiaResource>;
}

export interface BiaPersonale extends BiaCompilable {
  normal?: number; // per riferimento
  needed: number;
  support: number;
  resource: Array<BiaResourceSlot>; // = needed
  referer?: BiaResource;
}

export interface BiaProcessDetail extends BiaProcessPost {
  actions?: string[];
  cutoffs: Array<BiaCutOff>;
  v_impatti: Array<BiaImpactWrapper>;
  interdip: Array<BiaInterdip>;
  volumi: Array<BiaVolumi>;
  supplier: Array<BiaProcessSupplier>;
  personale: Array<BiaPersonale>;
  itProcedures?: BiaItProcedure[]; // TODO: aggiunto questo campo
  equipments?: BiaEquipmentModel[]; // TODO: aggiunto questo campo
  biaRisks?: BiaRisk[];
  states: { lbl: string; sel: boolean; val: number }[];
  master_plan: Planning;
}

export interface BiaCompilation {
  compilabile_cutoffs: BiaCompilationState;
  compilabile_equipments: BiaCompilationState;
  compilabile_idProcedures: BiaCompilationState;
  compilabile_interdip: BiaCompilationState;
  compilabile_personale: BiaCompilationState;
  compilabile_supplier: BiaCompilationState;
  compilabile_v_impatti: BiaCompilationState;
  compilabile_volumi: BiaCompilationState;
}

export enum BiaCompilationState {
  notIndicated = 0,
  notCompiled = 1,
  compiled = 2
}

export interface BiaMultiProcessResult {
  success: Array<BiaProcessHeader>;
  fail: Array<BiaProcessHeader>;      // Se contiene elementi, ci sono stati problemi, vedi campo error
  commit?: boolean;                 // Se true, le modifiche ai success sono committate
}


export interface BiaItProcedure {
  id?: number;
  name: string; // nome procedura informatica
  description: string; // descrizione procedura informatica
  managedByCompany: boolean; // procedura gestita direttamente da azienda
  referer: BiaSupplier;
  stato: BiaCompileState[];
  accessDataNoIt: boolean; // accesso ai dati per via non informatica
  timeRecoverType: BiaSelectData[]; // Tempo necessario per ricostruire un giorno di dati perduto
  timeRecover?: number; // Indicare il valore numerico riferito alla colonna precedente (Tempo necessario per ricostruire un giorno di dati perduto)
  backupProcedure: boolean; // Backup procedura gestito direttamente dall'Azienda
  frequencyBackupType: BiaSelectData[]; // frequenza di backup
  frequencyBackup?: number; // valore numerico per frequenza di backup
  storageMediaType: string; // tipo di supporto di memorizzazione
  storageMediaPosition: string; // localizzazione geografica in cui si trova il supporto
  note: string;
}

export interface BiaTool {
  id?: number;
  name?: string;
}

export interface BiaEquipmentModel {
  id?: number;
  typeEquipment: BiaTool; // tipo di attrezzatura
  qntNormal: number; // quantita in dotazione ordinariamente
  qntMin: number; // qnt minima per la continuita` operativa
  exclusiveUse: boolean; // utilizzo esclusivo
  exclusiveUseType: BiaSelectData[]; // TODO - se si (exclusiveUse) indicare da menu a tednina prodotto, servizio, processo, sub processo, attivita`
  supplier: BiaSupplier; // fornitore
  backupSupplier: BiaSupplier; // fornitore di backup
  note: string; // note
  stato: BiaCompileState[];
}

export interface BiaRisk extends BiaCompilable {
  risk: Risk; // rischio collegato
  description: string; // descrizione
  attach: VAttachment; // allegato
  proc?: { id: number; path: string; title: string }; // usato solo nelle chiamate /table
}

// export interface BiaNormativeResponse {
//   checked: boolean;
//   norms: BiaNormative[];
// }

export interface BiaNormative {
  id: number;
  title: string;
  norm: string;
  owner: 'this' | { id: number, itemID: string, title: string },
}
