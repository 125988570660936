import {EventEmitter, Injectable} from '@angular/core';
import {RieskoErrorComponent} from '../components/riesko-error/riesko-error.component';

@Injectable({providedIn: 'root'})
export class RieskoErrorNotificationService {

  constructor() {
  }

  emitError: EventEmitter<RieskoError> = new EventEmitter<RieskoError>();
}

export class RieskoError {
  public title: string;
  public body: string;
  public code?: number;
}
