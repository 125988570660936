import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeInterspersedService {

  _intervalId = null;
  _action: () => void;

  constructor() {
    console.log('TimeInterspersedService created');
    this._intervalId = setInterval(() => {
      if (this._action) {
        this._action();
      }
    }, 5000);
  }

  setInterspersedAction(action: () => void) {
    this._action = action;
  }

  clearInterspersedAction() {
    this._action = null;
  }

}
