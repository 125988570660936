import {Injectable} from '@angular/core';
import {Observable, from} from 'rxjs';
import {RestApiService} from './rest-api.service';
import {Response, ServiceBuilder} from 'ts-retrofit';
import {GenericResponse} from '../data/riesko/generic-response';
import {Account, Location, LoginResponse} from '../data/riesko/responses/login-response';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {FrameAzienda} from '../data/riesko/responses/frame-response';
import {TranslateService} from '@ngx-translate/core';
import {LangService} from './lang.service';
import {User} from '../data/riesko/user';

export interface ISignInCredentials {
  username: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(private api: RestApiService, private storage: StorageService, private router: Router,
              private langService: LangService) {
  }

  // signIn(credentials: ISignInCredentials): Observable<auth.UserCredential> {
  signIn(credentials: ISignInCredentials): Observable<Response<GenericResponse<LoginResponse>>> {
    const promise = this.api.doLogin(credentials.username, credentials.password);
    promise.then(value => {
      if (value.data.bSuccess && value.data.oData != null) {
        this.storage.loginData = value.data.oData;
      } else {
        this.storage.loginData = null;
      }
    });
    return from(promise);
    // return from(this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password));
  }

  getLoginPath(): string {
    if (this.storage.screenshotUrl != null && this.storage.screenshotUrl.length > 0) {
      return this.storage.screenshotUrl;
    }
    if (this.storage.loginData.oLocation.sDefault != null && this.storage.loginData.oLocation.sDefault.length >0) {
      return this.storage.loginData.oLocation.sDefault;
    }
    return '/';
  }

  signOut(): Observable<Response<GenericResponse<any>>> {
    this.storage.loginData = null;
    return from(this.api.doLogout());
  }

  get loginData(): LoginResponse {
    return this.storage.loginData;
  }

  get currentIDAzienda(): number {
    return this.storage.loginData?.oLocation?.IDAzienda;
  }

  get currentLocation(): Location {
    return this.storage.loginData?.oLocation;
  }

  get currentUser(): User {
    return this.storage.loginData?.oUser;
  }

  updateLoginData(loginResponse: LoginResponse, redirect: boolean = false) {
    this.storage.loginData = loginResponse;
    this.updateLanguage();
    if (redirect) {
      this.router.navigate([this.getLoginPath()]);
    }
  }

  private updateLanguage() {
    const languageCode = this.storage.loginData.oUser.sLang;
    console.log('default language: ' + languageCode);
    this.langService.language = languageCode;
  }

  isLoggedIn(): boolean {
    return this.storage.loginData != null;
  }

  isEsgLoggedIn(): boolean {
    return this.storage.loginData != null || this.storage.esgLoginData != null;
  }

}
