import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {Chart} from 'chart.js';
import {initUi_UUID} from './app/shared/utility-functions';


if (environment.production) {
  enableProdMode();
}

const color =
  (environment.isMultiColorActive || environment.isDarkSwitchActive) && localStorage.getItem(environment.themeColorStorageKey)
    ? localStorage.getItem(environment.themeColorStorageKey)
    : environment.defaultColor;


import('./assets/css/sass/themes/vien.' + color + '.scss').then(x => {
  localStorage.setItem(environment.themeColorStorageKey, color);
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
}).catch(() => {
  localStorage.removeItem(environment.themeColorStorageKey);
  window.location.reload();
});



// ===============================================================
//    SI OCCUPA DEL DRAWING DELL'INVESTIMENTO E RITORNO PER IL GRAFICO "chart-guage.component.ts"
//    TODO: spostare
// ===============================================================
Chart.Chart.pluginService.register({
  afterDraw(chart) {
    if (chart.config.additionalText != null && chart.config.additionalText.display !== null &&
      typeof chart.config.additionalText.display !== 'undefined' &&
      chart.config.additionalText.display) {
      drawTotals(chart);
    }
  },
});

function drawTotals(chart) {

  const width = chart.chart.width;
  const height = chart.chart.height;
  const ctx = chart.chart.ctx;

  ctx.restore();
  const fontSize = (height / 300).toFixed(2);
  ctx.font = fontSize + 'em sans-serif';
  ctx.textBaseline = 'middle';

  const text1 = chart.config.additionalText.text1;
  const text2 = chart.config.additionalText.text2;
  const raggio = Math.min(width / 2, height);
  const textX = Math.round(width / 2  - raggio) + (raggio / 2 - ctx.measureText(text1).width) / 2;
  // const textX2 = Math.round(width / 2  - raggio) + (raggio / 2 - ctx.measureText(text1).width) / 2; //+ (raggio / 2 - ctx.measureText(text1).width / 2);
  const textX2 = Math.round(width / 2  + raggio / 2) + (raggio / 2 - ctx.measureText(text1).width) / 2;
  const textY = height / 2 + raggio / 2 - 10;

  ctx.fillText(text1, textX, textY);
  ctx.fillText(text2, textX2, textY);
  ctx.save();
}
