<table class="table table-striped table-hover" *ngIf="treatment != null">
  <tr *ngIf="treatment.risk?.stato != null && treatment.risk.stato.length > 0">
    <th>{{'treatments.general-tab.state'|translate}}</th>
    <td>{{treatment.risk?.stato[0]?.label}}</td>
  </tr>
  <tr>
    <th>{{'treatments.general-tab.code'|translate}}</th>
    <td>{{treatment.code}}</td>
  </tr>
  <tr>
    <th>{{'treatments.general-tab.strategy'|translate}}</th>
    <td>{{getStrategy()}}</td>
  </tr>
  <tr>
    <th>{{'treatments.general-tab.priority'|translate}}</th>
    <td>{{getPrioroty()}}</td>
  </tr>
  <tr>
    <th>{{'treatments.general-tab.advance'|translate}}</th>
    <td>{{getAdvance()}}</td>
  </tr>
  <tr>
    <th>{{'treatments.general-tab.modality'|translate}}</th>
    <td>{{getModality()}}</td>
  </tr>
  <tr>
    <th>{{'treatments.general-tab.denomination'|translate}}</th>
    <td><span [innerHTML]="treatment.desc"></span></td>
  </tr>
  <tr>
    <th>{{'treatments.general-tab.details'|translate}}</th>
    <td class="limit-height">
      <span [innerHTML]="treatment.detail"></span>
    </td>
  </tr>
</table>
