import {Component, Input} from '@angular/core';
import {Treatment} from '../../../data/riesko/treatment';
import {TranslateService} from '@ngx-translate/core';
import {mod} from 'ngx-bootstrap/chronos/utils';

@Component({
  selector: 'app-treatment-detail-general-info',
  templateUrl: 'treatment-detail-general-info.component.html',
  styleUrls: ['treatment-detail-general-info.component.css'],
})
export class TreatmentDetailGeneralInfoComponent {
  @Input()
  treatment: Treatment;

  constructor(private translate: TranslateService) {
  }

  public getStrategy(): string {
    const selected = this.treatment.strat.find(x => x.selected);
    if (selected) {
      return selected.lab;
    }
    return '';
  }

  public getAdvance(): string {
    const selected = this.treatment.advance.find(x => x.selected);
    if (selected) {
      return selected.lab;
    }
    return '';
  }

  public getPrioroty(): string {
    const selected = this.treatment.priority.find(x => x.selected);
    if (selected) {
      return selected.lab;
    }
    return '';
  }

  public getModality(): string {
    const modalities = [];
    const prev = this.treatment.prev ? this.translate.instant('treatments.preventive') : '';
    if (prev.length > 0) {
      modalities.push(prev);
    }
    const resp = this.treatment.resp ? this.translate.instant('treatments.responsive') : '';
    if (resp.length > 0) {
      modalities.push(resp);
    }
    return modalities.join(', ');
  }


}
