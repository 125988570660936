import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {InspectorModel, InspectorService} from '../../services/inspector.service';
import {TabDirective, TabsetComponent} from 'ngx-bootstrap/tabs';
import {Risk} from '../../data/riesko/risk';
import {AllRestApiService} from '../../shared/all-rest-api.service';
import {Analysis} from '../../data/riesko/analysis';
import {Treatment} from '../../data/riesko/treatment';
import {Translations} from '../../services/translations.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-modal-inspector',
  templateUrl: './modal-inspector.component.html',
  styleUrls: ['./modal-inspector.component.scss']
})
export class ModalInspectorComponent implements OnInit {
  currentTabId: string;
  tabIndex: number;
  modalRef: BsModalRef;

  @ViewChild('tabSet')
  tabSet: TabsetComponent;

  get models(): InspectorModel[] {
    return this.inspectorService.openedModels;
  }

  constructor(private api: AllRestApiService,
              // private modalService: BsModalService,
              private translations: Translations, private translateService: TranslateService,
              private inspectorService: InspectorService) {
  }

  ngOnInit(): void {
    this.inspectorService.tabChanged.subscribe((tabId: string) => {
      try {
        document.querySelector('.tab-id-' + tabId).scrollIntoView();
      } catch (e) {
        // ignore error
      }
    });
  }

  closeTab(model: InspectorModel) {
    this.inspectorService.remove(model);
    if (this.getTabId(model) === this.currentTabId && this.inspectorService.openedModels.length) {
      setTimeout(() => {
        // this.tabSet.tabs[0].selectTab.emit();
        this.currentTabId = this.inspectorService.getTabId(this.inspectorService.openedModels[0]);
      }, 1);
    }
  }

  changeTab(event: TabDirective) {
    this.currentTabId = event.id;
    const model = this.inspectorService.findModelByTabId(this.currentTabId);
    this.reloadData(model);
  }

  getTabId(model: InspectorModel) {
    return this.inspectorService.getTabId(model);
  }

  async reloadData(model: InspectorModel) {
    switch (model.type) {
      case 'Risk':
        try {
          const responseRisk = await this.api.riesko.getRiskInformation(model.id);
          model.rawData = responseRisk.data.oData;
        } catch (e) {
          model.rawData = {code: 'Loading Error'} as Risk;
        }
        break;
      case 'Analysis':
        try {
          const responseAnalysis = await this.api.riesko.getAnalysisInformation(model.analysisType, model.id as string);
          model.rawData = responseAnalysis.data.oData;
        } catch (e) {
          model.rawData = {code: 'Loading Error'} as Analysis;
        }
        break;
      case 'Treatment':
        try {
          const responseTreatment = await this.api.riesko.getTreatmentInfo(model.id as string);
          model.rawData = responseTreatment.data.oData;
        } catch (e) {
          model.rawData = {code: 'Loading Error'} as Treatment;
        }
        break;
    }
  }

  getTitle(model: InspectorModel) {
    if (!model.rawData) {
      return '';
    }
    return model.rawData.code;
  }

  getIcon(model: InspectorModel) {
    if (!model.rawData) {
      return '';
    }
    switch (model.type) {
      case 'Risk':
        return 'iconsminds-cloud-weather';
      case 'Analysis':
        switch (model.analysisType) {
          case 'gross':
            return 'iconsminds-flask';
          case 'net':
            return 'iconsminds-summer';
        }
        break;
      case 'Treatment':
        return 'iconsminds-umbrella-2';
    }
    return 'modal-inspector.component@getIcon()';
  }
}

