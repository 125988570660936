import {EventEmitter, Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class HeadingService {

  public onChangeCustomTitle: EventEmitter<string | null> = new EventEmitter<string | null>();
  public onChangeHeadingActions: EventEmitter<HeadingAction[]> = new EventEmitter<HeadingAction[]>();
  public onChangeSubTitleResolver: EventEmitter<(title: string, index: number) => HeadingBreadItem|HeadingBreadItem[]> = new EventEmitter<(title: string, index: number) => HeadingBreadItem|HeadingBreadItem[]>();

  private _customTitle: string;
  private _headingActions: HeadingAction[];

  private _subTitleResolver: (title: string, index: number) => HeadingBreadItem|HeadingBreadItem[];

  public get subTitleResolver(): (title: string, index: number) => HeadingBreadItem|HeadingBreadItem[] {
    if (!this._subTitleResolver) {
      this.resetSubTitleResolver();
    }
    return this._subTitleResolver;
  }

  public set subTitleResolver(value: (title: string, index: number) => HeadingBreadItem|HeadingBreadItem[]) {
    this._subTitleResolver = value;
    this.onChangeSubTitleResolver.emit(value);
  }

  public useLoadingSubTitleResolver(): void {
    this.subTitleResolver = emptyLoadingSubTitleResolver;
  }

  public resetSubTitleResolver(): void {
    this._subTitleResolver = (title: string, index: number) => {
      return {
        label: title,
        link: '',
        visible: true
      };
    };
  }

  public get customTitle(): string {
    return this._customTitle;
  }

  public set customTitle(value: string | null) {
    this._customTitle = value;
    this.onChangeCustomTitle.emit(value);
  }

  public get headingActions(): HeadingAction[] {
    return this._headingActions;
  }

  public set headingActions(actions: HeadingAction[]) {
    this._headingActions = actions ?? [];
    this.onChangeHeadingActions.emit(this._headingActions);
  }

  public reset(useCustomTitle: boolean, useCustomHeadingActions: boolean): void {
    this.resetSubTitleResolver();
    if (useCustomHeadingActions) {
      this.headingActions = null;
    }
    if (useCustomTitle) {
      this.customTitle = null;
    }
  }
}

export interface HeadingBreadItem {
  label: string;
  visible: boolean;
  link: string|null;
  clickable?: boolean;
  action?: () => void;
}

export interface HeadingActionItem {
  label: string;
  action: () => void;
}

export interface HeadingAction extends HeadingActionItem {
  label: string;
  action: () => void;
  primary: boolean;
  dropdownItems?: HeadingActionItem[];
}

export function emptyLoadingSubTitleResolver(title: string, index: number): HeadingBreadItem {
  if (index === 0) {
    return {
      label: '<i class="fa fa-spinner fa-spin"></i>',
      visible: true,
      link: '',
    };
  }
  return {
    label: '',
    visible: false,
    link: '',
  };
}
