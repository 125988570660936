import {EventEmitter, Injectable} from '@angular/core';
import {ModalInspectorComponent} from '../components/modal-inspector/modal-inspector.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Risk} from '../data/riesko/risk';
import {Analysis} from '../data/riesko/analysis';
import {Treatment} from '../data/riesko/treatment';

@Injectable()
export class InspectorService {
  modalRef: BsModalRef;
  isModalHidden = true;
  openedModels: InspectorModel[] = [];
  tabChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private modalService: BsModalService) {
  }

  open(model: InspectorModel) {
    console.log({opneningInspector: model});
    let existing = this.openedModels.find(m => m.id === model.id);
    if (!existing) {
      this.openedModels.push(model);
      existing = model;
    }
    this.showModal(existing);
  }

  showModal(focusModel: InspectorModel) {
    if (!this.modalRef || this.isModalHidden) {
      this.modalRef = this.modalService.show(ModalInspectorComponent, {class: 'modal-xl'});
      this.modalRef.onHide.subscribe(() => {
        this.isModalHidden = true;
      });
      this.isModalHidden = false;
    }
    const component = this.modalRef.content as ModalInspectorComponent;
    component.currentTabId = this.getTabId(focusModel);
    this.tabChanged.emit(component.currentTabId);
  }


  hideModal() {
    this.modalRef?.hide();
  }

  remove(model: InspectorModel) {
    // remove the model from openedModels
    this.openedModels = this.openedModels.filter(x => !(x.id === model.id && x.type === model.type));
    if (!this.openedModels.length) {
      this.hideModal();
    }
  }

  getTabId(model: InspectorModel) {
    return model.type + '_' + model.id;
  }

  findModelByTabId(tabId: string) {
    return this.openedModels.find(x => this.getTabId(x) === tabId);
  }
}

export class InspectorModel {
  type: 'Risk' | 'Analysis' | 'Treatment';
  id: string;
  rawData: Risk | Analysis | Treatment;
  analysisType?: 'gross' | 'net' | undefined;

  constructor() {
  }
}

