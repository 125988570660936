import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {TokensService} from './tokens.service';
import {ACCEPT_JSON, CONTENT_TYPE_JSON, createRestApiService} from './base-rest-api';
import {RestApiService} from './rest-api.service';
import {BasePath, BaseService, Body, GET, Header, Headers, Path, POST, Response} from 'ts-retrofit';
import {GenericResponse} from '../data/riesko/generic-response';
import {LoginResponse} from '../data/riesko/responses/login-response';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {
  BiaCutOff, BiaEquipmentModel,
  BiaImpact,
  BiaInterdip, BiaItProcedure, BiaMonitor,
  BiaMultiProcessResult,
  BiaPersonale,
  BiaProcess,
  BiaProcessDetail,
  BiaProcessHeader,
  BiaProcessPost,
  BiaProcessSupplier,
  BiaResource, BiaRisk, BiaSite, BiaSupplier, BiaSupplies, BiaTool,
  BiaUser,
  BiaVolumi
} from '../data/riesko/responses/bia-responses';
import {RieskoErrorNotificationService} from './riesko-error-notification.service';
import {MonitorData, MonitorResponse, MonitorTemplateForm} from '../data/riesko/responses/monitor-template-form';
import {Planning, PlanningAssociation, PlanningSearchResponse} from '../data/riesko/responses/planning';
import {Issue} from '../data/riesko/responses/issue';
import {SurveyEditData, SurveyEditDataQuestion, SurveyForm, SurveyHeader} from '../data/riesko/responses/survey';

@Injectable({providedIn: 'root'})
@BasePath(environment.apiUrl + environment.basePrefixApiUrl)
export class BiaRestApiService extends BaseService {
  // ===================================================================================
  //    GET SESSION
  // ===================================================================================
  @GET('/session')
  @Headers({'Accept': 'application/json'})
  // ===================================================================================
  //    PING
  // ===================================================================================
  @GET('')
  async ping(): Promise<any> {
    return {} as Response<any>;
  }


  // ===================================================================================
  //    LIST THEM ALL
  // ===================================================================================
  @GET('/bia/list/{stateId}')
  @Headers(ACCEPT_JSON)
  async getAllProcesses(@Path('stateId') stateId: number | string): Promise<Response<GenericResponse<BiaProcessHeader[]>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    CREATE
  // ===================================================================================
  @POST('/bia/create')
  @Headers(ACCEPT_JSON)
  async createProcess(): Promise<Response<GenericResponse<BiaProcessDetail>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    OPEN PROCESS
  // ===================================================================================
  @GET('/bia/proc/{id}/open')
  @Headers(ACCEPT_JSON)
  async openProcess(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaProcessDetail>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE PROCESS
  // ===================================================================================
  @POST('/bia/proc/{id}/drop')
  @Headers(ACCEPT_JSON)
  async deleteProcess(@Path('id') id: number | string): Promise<Response> {
    return {} as Response;
  }

  // ===================================================================================
  //    EDIT PROCESS
  // ===================================================================================
  @GET('/bia/proc/{id}/edit')
  @Headers(ACCEPT_JSON)
  async editProcess(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaProcessDetail>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE PROCESS
  // ===================================================================================
  @POST('/bia/proc/{id}')
  @Headers({...CONTENT_TYPE_JSON, ...ACCEPT_JSON})
  async saveProcess(@Path('id') id: number | string, @Body body: BiaProcessPost): Promise<Response<GenericResponse<any>>> { // TODO: Ritorno?
    return {} as Response;
  }

  // ===================================================================================
  //    GET NORMATIVES
  // ===================================================================================
  @GET('/bia/proc/{id}/norms')
  @Headers(ACCEPT_JSON)
  async getNorms(@Path('id') id: number | string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CHANGE PROCESS STATUS
  // ===================================================================================
  @POST('/bia/proc/{id}/changestatus/{statusId}')
  @Headers(ACCEPT_JSON)
  async changeProcessState(@Path('id') id: number, @Path('statusId') statusId: number): Promise<Response<GenericResponse<BiaMultiProcessResult>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    NEW SUB PROCESS
  // ===================================================================================
  @POST('/bia/proc/{id}/addchild')
  @Headers(ACCEPT_JSON)
  async addChildProcess(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaProcessDetail>>> {
    return {} as Response;
  }

  @POST('/bia/proc/{id}/addchild')
  @Headers(ACCEPT_JSON)
  async addChildProcessWithOptions(@Path('id') id: number | string, @Body options: { option: 'subprocess' | 'activity' }): Promise<Response<GenericResponse<BiaProcessDetail>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SET PROCESS'S STATE
  // ===================================================================================
  @POST('/bia/proc/{id}/changestatus/{status}')
  @Headers(ACCEPT_JSON)
  async setProcessStatus(@Path('id') id: number | string, @Path('status') status: string): Promise<Response<GenericResponse<BiaProcess>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    INCREMENT PROCESS'S PRIORITY
  // ===================================================================================
  @POST('/bia/proc/{id}/raisepriority')
  @Headers(ACCEPT_JSON)
  async incrementProcessPriority(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaProcess[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DECREMENT PROCESS'S PRIORITY
  // ===================================================================================
  @POST('/bia/proc/{id}/lowerpriority')
  @Headers(ACCEPT_JSON)
  async decrementProcessPriority(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaProcess[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    NEW CUTOFF
  // ===================================================================================
  @POST('/bia/proc/{id}/add/cutoff')
  @Headers(ACCEPT_JSON)
  async addCutOff(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaCutOff>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE CUTOFF
  // ===================================================================================
  @POST('/bia/cutoff/{id}/drop')
  @Headers(ACCEPT_JSON)
  async removeCutOff(@Path('id') id: number | string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET CUTOFF
  // ===================================================================================
  @GET('/bia/cutoff/{id}')
  @Headers(ACCEPT_JSON)
  async getCutOff(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaCutOff>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET CUTOFF TABLE
  // ===================================================================================
  @GET('/bia/cutoff/table')
  @Headers(ACCEPT_JSON)
  async getCutOffTable(): Promise<Response<GenericResponse<BiaCutOff[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE CUTOFF
  // ===================================================================================
  @POST('/bia/cutoff/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveCutOff(@Path('id') id: number | string, @Body cutoff: BiaCutOff): Promise<Response<GenericResponse<BiaCutOff>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET PERSON BY ID
  // ===================================================================================
  @GET('/bia/users/{search}')
  @Headers(ACCEPT_JSON)
  async searchBiaUsers(@Path('search') search: string): Promise<Response<GenericResponse<BiaUser[]>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    NEW VOLUME
  // ===================================================================================
  @POST('/bia/proc/{id}/add/volumi')
  @Headers(ACCEPT_JSON)
  async addVolume(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaVolumi>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE VOLUME
  // ===================================================================================
  @POST('/bia/volumi/{id}/drop')
  @Headers(ACCEPT_JSON)
  async removeVolume(@Path('id') id: number | string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET VOLUME
  // ===================================================================================
  @GET('/bia/volumi/{id}')
  @Headers(ACCEPT_JSON)
  async getVolume(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaVolumi>>> {
    return {} as Response;
  }

  @GET('/bia/volumi/table')
  @Headers(ACCEPT_JSON)
  async getVolumesTable(): Promise<Response<GenericResponse<BiaVolumi[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE VOLUME
  // ===================================================================================
  @POST('/bia/volumi/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveVolume(@Path('id') id: number | string, @Body cutoff: BiaVolumi): Promise<Response<GenericResponse<BiaVolumi>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    NEW INTERDIP
  // ===================================================================================
  @POST('/bia/proc/{id}/add/interdip')
  @Headers(ACCEPT_JSON)
  async addInterdip(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaInterdip>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE INTERDIP
  // ===================================================================================
  @POST('/bia/interdip/{id}/drop')
  @Headers(ACCEPT_JSON)
  async removeInterdip(@Path('id') id: number | string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET INTERDIP
  // ===================================================================================
  @GET('/bia/interdip/{id}')
  @Headers(ACCEPT_JSON)
  async getInterdip(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaInterdip>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET INTERDIP TABLE
  // ===================================================================================
  @GET('/bia/interdip/table')
  @Headers(ACCEPT_JSON)
  async getInterdipsTable(): Promise<Response<GenericResponse<BiaInterdip[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE INTERDIP
  // ===================================================================================
  @POST('/bia/interdip/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveInterdip(@Path('id') id: number | string, @Body cutoff: BiaInterdip): Promise<Response<GenericResponse<BiaInterdip>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE IMPACT
  // ===================================================================================
  @POST('/bia/impact/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveImpact(@Path('id') id: number, @Body impact: BiaImpact): Promise<Response<GenericResponse<BiaImpact>>> {
    return {} as Response;
  }

  @GET('/bia/impact/table/operativo')
  @Headers(ACCEPT_JSON)
  async getImpactOperativoTable(): Promise<Response<GenericResponse<BiaImpact[]>>> {
    return {} as Response;
  }

  @GET('/bia/impact/table/reputazionale')
  @Headers(ACCEPT_JSON)
  async getImpactReputazionaleTable(): Promise<Response<GenericResponse<BiaImpact[]>>> {
    return {} as Response;
  }

  @GET('/bia/impact/table/normativo')
  @Headers(ACCEPT_JSON)
  async getImpactNormativoTable(): Promise<Response<GenericResponse<BiaImpact[]>>> {
    return {} as Response;
  }

  @GET('/bia/impact/table/economico')
  @Headers(ACCEPT_JSON)
  async getImpactEconomicoTable(): Promise<Response<GenericResponse<BiaImpact[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CREATE PERSONALE
  // ===================================================================================
  @POST('/bia/proc/{idProc}/add/personale')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async createPersonale(@Path('idProc') idProc: number): Promise<Response<GenericResponse<BiaPersonale>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET PERSONALE
  // ===================================================================================
  @GET('/bia/personale/table')
  @Headers(ACCEPT_JSON)
  async getPersonaleTable(): Promise<Response<GenericResponse<BiaPersonale[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET PERSONALE BY ID
  // ===================================================================================
  @GET('/bia/personale/{id}')
  @Headers(ACCEPT_JSON)
  async getPersonaleById(@Path('id') id: number): Promise<Response<GenericResponse<BiaPersonale>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE PERSONALE
  // ===================================================================================
  @POST('/bia/personale/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async savePersonale(@Path('id') id: number, @Body personale: BiaPersonale): Promise<Response<GenericResponse<BiaPersonale>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE PERSONALE
  // ===================================================================================
  @POST('/bia/personale/{id}/drop')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async deletePersonale(@Path('id') id: number): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    CREATE RESOURCE
  // ===================================================================================
  @POST('/bia/resource/create')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async createResource(): Promise<Response<GenericResponse<BiaResource>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET RESOURCES LIST
  // ===================================================================================
  @GET('/bia/resource')
  @Headers(ACCEPT_JSON)
  async getAllResources(): Promise<Response<GenericResponse<BiaResource[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SEARCH RESOURCE
  // ===================================================================================
  @GET('/bia/resource/search/{filterText}')
  @Headers(ACCEPT_JSON)
  async searchResource(@Path('filterText') filterText: string): Promise<Response<GenericResponse<BiaResource[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET RESOURCE BY ID
  // ===================================================================================
  @GET('/bia/resource/{id}')
  @Headers(ACCEPT_JSON)
  async getResourceById(@Path('id') id: number): Promise<Response<GenericResponse<BiaResource>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE RESOURCE
  // ===================================================================================
  @POST('/bia/resource/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveResource(@Path('id') id: number, @Body resource: BiaResource): Promise<Response<GenericResponse<BiaResource>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE RESOURCE
  // ===================================================================================
  @POST('/bia/resource/{id}/drop')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async deleteResource(@Path('id') id: number): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    CREATE SUPPLIER
  // ===================================================================================
  @POST('/bia/proc/{idProc}/add/supplier')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async createSupplier(@Path('idProc') idProc: number): Promise<Response<GenericResponse<BiaProcessSupplier>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET SUPPLIER
  // ===================================================================================
  @GET('/bia/supplier')
  @Headers(ACCEPT_JSON)
  async getAllSuppliers(): Promise<Response<GenericResponse<BiaProcessSupplier[]>>> {
    return {} as Response;
  }

  @GET('/bia/supplier/table')
  @Headers(ACCEPT_JSON)
  async getSuppliersTable(): Promise<Response<GenericResponse<BiaProcessSupplier[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET SUPPLIER BY ID
  // ===================================================================================
  @GET('/bia/supplier/{id}')
  @Headers(ACCEPT_JSON)
  async getSupplierById(@Path('id') id: number): Promise<Response<GenericResponse<BiaProcessSupplier>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE SUPPLIER
  // ===================================================================================
  @POST('/bia/supplier/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveSupplier(@Path('id') id: number, @Body supplier: BiaProcessSupplier): Promise<Response<GenericResponse<BiaProcessSupplier>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    CREATE SUPPLIES
  // ===================================================================================
  @POST('/bia/supplies/create')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async createSupplies(): Promise<Response<GenericResponse<BiaSupplies>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET SUPPLIES
  // ===================================================================================
  @GET('/bia/supplies')
  @Headers(ACCEPT_JSON)
  async getAllSupplies(): Promise<Response<GenericResponse<BiaSupplies[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET SUPPLIES BY ID
  // ===================================================================================
  @GET('/bia/supplies/{id}')
  @Headers(ACCEPT_JSON)
  async getSuppliesById(@Path('id') id: number): Promise<Response<GenericResponse<BiaSupplies>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE SUPPLIES
  // ===================================================================================
  @POST('/bia/supplies/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveSupplies(@Path('id') id: number, @Body supplies: BiaSupplies): Promise<Response<GenericResponse<BiaSupplies>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE SUPPLIES
  // ===================================================================================
  @POST('/bia/supplies/{id}/drop')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async deleteSupplies(@Path('id') id: number): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    GET ENTERPRISE SUPPLIER
  // ===================================================================================
  @GET('/bia/enterprise')
  @Headers(ACCEPT_JSON)
  async getAllEnterpriseSuppliers(): Promise<Response<GenericResponse<BiaSupplier[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET ENTERPRISE SUPPLIER BY ID
  // ===================================================================================
  @GET('/bia/enterprise/{id}')
  @Headers(ACCEPT_JSON)
  async getEnterpriseSupplierById(@Path('id') id: string): Promise<Response<GenericResponse<BiaSupplier>>> { // TODO: verficare tipo di ritorno
    return {} as Response;
  }

  // ===================================================================================
  //    SEARCH ENTERPRISE SUPPLIER
  // ===================================================================================
  @GET('/bia/enterprise/search/{filterText}')
  @Headers(ACCEPT_JSON)
  async searchEnterpriseSupplier(@Path('filterText') filterText: string): Promise<Response<GenericResponse<BiaSupplier[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE ENTERPRISE SUPPLIER
  // ===================================================================================
  @POST('/bia/enterprise/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveEnterpriseSupplier(@Path('id') id: number | string, @Body supplier: BiaSupplier): Promise<Response<GenericResponse<BiaSupplier>>> { // TODO: verficare tipo di ritorno
    return {} as Response;
  }

  // ===================================================================================
  //    CREATE ENTERPRISE SUPPLIER
  // ===================================================================================
  @POST('/bia/enterprise/create')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async createEnterpriseSupplier(): Promise<Response<GenericResponse<BiaSupplier>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE ENTERPRISE SUPPLIER
  // ===================================================================================
  @POST('/bia/enterprise/{id}/drop')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async deleteEnterpriseSupplier(@Path('id') id: number | string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    CREATE NEW IT PROCEDURE
  // ===================================================================================
  @POST('/bia/proc/{id}/add/itprocedure')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async newItProcedure(@Path('id') id: number | string): Promise<Response<GenericResponse<BiaItProcedure>>> { // TODO:
    return {} as Response;
  }

  @GET('/bia/itprocedures/table')
  @Headers(ACCEPT_JSON)
  async getItProceduresTable(): Promise<Response<GenericResponse<BiaItProcedure[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    UPDATE IT PROCEDURE -
  // ===================================================================================
  @POST('/bia/itprocedures/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async updateItProcedure(@Path('id') id: string | number, @Body itProcedure: BiaItProcedure): Promise<Response<GenericResponse<BiaItProcedure>>> { // TODO:
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE IT PROCEDURE
  // ===================================================================================
  @POST('/bia/itprocedures/{id}/drop')
  @Headers(ACCEPT_JSON)
  async removeItProcedure(@Path('id') id: number | string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CREATE NEW EQUIPMENT
  // ===================================================================================
  @POST('/bia/proc/{idProc}/add/equipment')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async newEquipment(@Path('idProc') idProc: number | string): Promise<Response<GenericResponse<BiaEquipmentModel>>> { // TODO:
    return {} as Response;
  }

  @GET('/bia/equipments/table')
  @Headers(ACCEPT_JSON)
  async getEquipmentsTable(): Promise<Response<GenericResponse<BiaEquipmentModel[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    UPDATE EQUIPMENT
  // ===================================================================================
  @POST('/bia/equipments/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async updateEquipment(@Path('id') id: string | number, @Body body: BiaEquipmentModel): Promise<Response<GenericResponse<BiaEquipmentModel>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DELETE EQUIPMENT
  // ===================================================================================
  @POST('/bia/equipments/{id}/drop')
  @Headers(ACCEPT_JSON)
  async removeEquipment(@Path('id') id: number | string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET ALL SITES
  // ===================================================================================
  @GET('/bia/sites')
  @Headers(ACCEPT_JSON)
  async getAllBiaSites(): Promise<Response<GenericResponse<BiaSite[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SEARCH TOOLS
  // ===================================================================================
  @GET('/bia/sites/search/{filterText}')
  @Headers(ACCEPT_JSON)
  async searchBiaSite(@Path('filterText') filterText: string): Promise<Response<GenericResponse<BiaSite[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CREATE TOOL
  // ===================================================================================
  @POST('/bia/sites/create')
  @Headers(ACCEPT_JSON)
  async createBiaSite(): Promise<Response<GenericResponse<BiaSite>>> {
    return {} as Response;
  }

  @POST('/bia/sites/{id}')
  @Headers(ACCEPT_JSON)
  async saveBiaSite(@Path('id') id: number | string, @Body biaTool: BiaSite): Promise<Response<GenericResponse<BiaSite>>> {
    return {} as Response;
  }

  @POST('/bia/sites/{id}/drop')
  @Headers(ACCEPT_JSON)
  async deleteBiaSite(@Path('id') id: number | string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    GET ALL TOOLS
  // ===================================================================================
  @GET('/bia/tools')
  @Headers(ACCEPT_JSON)
  async getAllBiaTools(): Promise<Response<GenericResponse<BiaTool[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SEARCH TOOLS
  // ===================================================================================
  @GET('/bia/tools/search/{filterText}')
  @Headers(ACCEPT_JSON)
  async searchBiaTools(@Path('filterText') filterText: string): Promise<Response<GenericResponse<BiaTool[]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CREATE TOOL
  // ===================================================================================
  @POST('/bia/tools/create')
  @Headers(ACCEPT_JSON)
  async createBiaTool(): Promise<Response<GenericResponse<BiaTool>>> {
    return {} as Response;
  }

  @POST('/bia/tools/{id}')
  @Headers(ACCEPT_JSON)
  async saveBiaTool(@Path('id') id: number | string, @Body biaTool: BiaTool): Promise<Response<GenericResponse<BiaTool>>> {
    return {} as Response;
  }

  @POST('/bia/tools/{id}/drop')
  @Headers(ACCEPT_JSON)
  async deleteBiaTool(@Path('id') id: number | string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    NEW BIA RISK - TODO WITTO
  // ===================================================================================
  @POST('/bia/proc/{idProc}/add/risk/{riskId}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async newBiaRisk(@Path('idProc') id: number | string, @Path('riskId') riskId: string | number): Promise<Response<GenericResponse<BiaRisk>>> { // TODO:
    return {} as Response;
  }

  // ===================================================================================
  //    UPDATE IT PROCEDURE - TODO WITTO
  // ===================================================================================

  @GET('/bia/risks/table')
  @Headers(ACCEPT_JSON)
  async getBiaRisksTable(): Promise<Response<GenericResponse<BiaRisk[]>>> {
    return {} as Response;
  }

  @POST('/bia/risks/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async updateBiaRisk(@Path('id') id: number, @Body biaRisk: BiaRisk): Promise<Response<GenericResponse<BiaRisk>>> { // TODO:
    return {} as Response;
  }

  @POST('/bia/{typeEntity}/{id}/{compiled}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async updateCompiledBiaEntity(@Path('typeEntity') typeEntity: 'cutoff' | 'volumi' | 'interdip' | 'supplier' | 'equipments' | 'itprocedures',
                                @Path('id') id: number,
                                @Path('compiled') compiled: 'compiled' | 'uncompiled')
    : Promise<Response<GenericResponse<{ success: boolean, fields: string[] }>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    PRINT
  // ===================================================================================
  @GET('/bia/proc/{id}/print')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async print(@Path('id') id: number): Promise<Response<GenericResponse<string>>> {
    return {} as Response;
  }

  @GET('/monitor/template/{model}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async getMonitorTemplate(@Path('model') model: string): Promise<Response<GenericResponse<MonitorTemplateForm>>> {
    return {} as Response;
  }

  @GET('/monitor')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async getMonitors(): Promise<Response<GenericResponse<BiaMonitor[]>>> {
    return {} as Response;
  }

  @GET('/monitor/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async getMonitor(@Path('id') id: number): Promise<Response<GenericResponse<MonitorResponse>>> {
    return {} as Response;
  }

  @POST('/monitor/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveMonitor(@Path('id') id: number, @Body monitor: { data: MonitorData }): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/monitor/{id}/submit')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async submitMonitor(@Path('id') id: number, @Body monitor: { data: MonitorData }): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @GET('/bia/planning/{filterStatusParameter}')
  @Headers({...CONTENT_TYPE_JSON, ...ACCEPT_JSON})
  async getAllPlanning(@Path('filterStatusParameter') filterStatusParameter: '' | 'list-draft' | 'list-approve' | 'list-all')
    : Promise<Response<GenericResponse<PlanningSearchResponse>>> {
    return {} as Response;
  }

  @GET('/bia/planning/{id}')
  @Headers({...CONTENT_TYPE_JSON, ...ACCEPT_JSON})
  async openPlanning(@Path('id') id: number): Promise<Response<GenericResponse<Planning>>> {
    return {} as Response;
  }

  @GET('/bia/planning/{id}/edit')
  @Headers({...CONTENT_TYPE_JSON, ...ACCEPT_JSON})
  async editPlanning(@Path('id') id: number): Promise<Response<GenericResponse<Planning>>> {
    return {} as Response;
  }

  @POST('/bia/planning/create')
  @Headers({...CONTENT_TYPE_JSON, ...ACCEPT_JSON})
  async createPlanning(): Promise<Response<GenericResponse<Planning>>> {
    return {} as Response;
  }

  @POST('/bia/planning/{id}')
  @Headers({...CONTENT_TYPE_JSON, ...ACCEPT_JSON})
  async savePlanning(@Path('id') id: number, @Body body: Planning): Promise<Response<GenericResponse<Planning>>> {
    return {} as Response;
  }

  @POST('/bia/planning/{id}/changestatus/{stateId}')
  @Headers(ACCEPT_JSON)
  async changePlanState(@Path('id') id: number, @Path('stateId') state: number): Promise<Response<GenericResponse<Issue>>> {
    return {} as Response;
  }

  @POST('/bia/proc/{biaId}/add/assoc/{planId}')
  @Headers(ACCEPT_JSON)
  async associateBiaPlanning(@Path('biaId') biaId: number | string, @Path('planId') planId: number): Promise<Response<GenericResponse<PlanningAssociation>>> {
    return {} as Response;
  }

  @POST('/bia/assoc/{id}')
  @Headers(ACCEPT_JSON)
  async saveBiaPlanningAssociation(@Path('id') id: number, @Body biaPlanningAssociation: PlanningAssociation): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @GET('/bia/assoc/{id}/edit')
  @Headers(ACCEPT_JSON)
  async editBiaPlanningAssociation(@Path('id') id: number): Promise<Response<GenericResponse<PlanningAssociation>>> {
    return {} as Response;
  }

  @GET('/bia/assoc/{id}/print')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async printBiaPlanningAssociation(@Path('id') id: number): Promise<Response<GenericResponse<string>>> {
    return {} as Response;
  }

  @GET('/survey/form/{id}')
  @Headers(ACCEPT_JSON)
  async getSurveyHeaderForm(@Path('id') id: string): Promise<Response<GenericResponse<SurveyForm>>> {
    return {} as Response;
  }

  @GET('/survey/form/{id}/get')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async getAnonymousSurvey(@Path('id') id: string): Promise<Response<GenericResponse<MonitorResponse>>> {
    return {} as Response;
  }

  @POST('/survey/form/{id}/save')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveAnonymousSurvey(@Path('id') id: string, @Body monitor: { data: MonitorData }): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/survey/form/{id}/submit')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async submitAnonymousSurvey(@Path('id') id: string, @Body monitor: { data: MonitorData }): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @GET('/admin/survey')
  @Headers(ACCEPT_JSON)
  async getSurveyList(): Promise<Response<GenericResponse<SurveyHeader[]>>> {
    return {} as Response;
  }

  @GET('/admin/survey/{id}')
  @Headers(ACCEPT_JSON)
  async getSurvey(@Path('id') id: number): Promise<Response<GenericResponse<SurveyEditData[]>>> {
    return {} as Response;
  }

  @POST('/admin/survey/add')
  @Headers(ACCEPT_JSON)
  async createSurvey(): Promise<Response<GenericResponse<SurveyHeader>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/section/add')
  @Headers(ACCEPT_JSON)
  async addSurveySection(@Path('id') id: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/section/{sectionId}')
  @Headers(ACCEPT_JSON)
  async updateSurveySection(@Path('id') id: number,
                            @Path('sectionId') sectionId: number,
                            @Body survey: SurveyEditData): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/section/{sectionId}/drop')
  @Headers(ACCEPT_JSON)
  async deleteSurveySection(@Path('id') id: number,
                            @Path('sectionId') sectionId: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/section/{sectionId}/raise')
  @Headers(ACCEPT_JSON)
  async raiseSurveySection(@Path('id') id: number,
                           @Path('sectionId') sectionId: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/section/{sectionId}/lower')
  @Headers(ACCEPT_JSON)
  async lowerSurveySection(@Path('id') id: number,
                           @Path('sectionId') sectionId: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/section/{sectionId}/create')
  @Headers(ACCEPT_JSON)
  async addSurveyQuestion(@Path('id') id: number,
                          @Path('sectionId') sectionId: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }


  @POST('/admin/survey/{id}/question/{questionId}/raise')
  @Headers(ACCEPT_JSON)
  async raiseSurveyQuestion(@Path('id') id: number,
                            @Path('questionId') questionId: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/question/{questionId}/lower')
  @Headers(ACCEPT_JSON)
  async lowerSurveyQuestion(@Path('id') id: number,
                            @Path('questionId') questionId: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/question/{questionId}/drop')
  @Headers(ACCEPT_JSON)
  async deleteSurveyQuestion(@Path('id') id: number,
                             @Path('questionId') sectionId: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/admin/survey/{id}/question/{questionId}')
  @Headers(ACCEPT_JSON)
  async updateSurveyQuestion(@Path('id') id: number,
                             @Path('questionId') sectionId: number,
                             @Body question: SurveyEditDataQuestion): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

}


// ===================================================================================
//    FACTORY FUNCTION
// ===================================================================================

export function createBiaRestApiService(translate: TranslateService,
                                        storage: StorageService,
                                        router: Router,
                                        crsfService: TokensService,
                                        rieskoErrorNotificationService: RieskoErrorNotificationService) {
  return createRestApiService(translate, storage, router, crsfService, rieskoErrorNotificationService, BiaRestApiService);
}
