<div class="container-fluid p-4" *ngIf="treatment">
  <!-- Title -->
  <div class="row">
    <div class="col-10">
      <h3><i class="iconsminds-umbrella-2"></i> {{ treatment.code }}</h3>
    </div>
    <div class="col-2 text-right">
      <button class="btn btn-outline-primary btn-xs" (click)="open()">
        <!-- eye icon -->
        <i class="fa fa-eye"></i>
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="row">
    <div class="col-12">
      <tabset *ngIf="treatment">
        <tab [heading]="'treatments.general-tab.title'|translate">
          <app-treatment-detail-general-info [treatment]="treatment"></app-treatment-detail-general-info>
        </tab>
        <tab [heading]="'treatments.economics-tab.title'|translate">
          <app-treatment-economics-info [treatment]="treatment"></app-treatment-economics-info>
        </tab>
        <tab [heading]="'risks.popup-attachments-list' | translate">
          <app-risk-attachments-info [attachContainer]="treatment" uploadPath="treatments"></app-risk-attachments-info>
        </tab>
      </tabset>
    </div>
  </div>

</div>
