import {Component, Input} from '@angular/core';
import {NodeSelectorService} from '../../containers/layout/topnav/node-selector.service';
import {Analysis} from '../../data/riesko/analysis';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Translations} from '../../services/translations.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-analysis-details',
  templateUrl: './analysis-details.component.html'
})
export class AnalysisDetailsComponent {

  @Input()
  analysis: Analysis;

  @Input()
  isGross: boolean;

  constructor(public translations: Translations,
              private router: Router,
              private nodeSelector: NodeSelectorService,
              private modal: BsModalRef) {
  }

  getRiskValutatedAtTabTitle(): string {
    return this.nodeSelector.currentNode?.sLabel ?? '';
  }

  close(): void {
    this.modal.hide();
  }

  open() {
    this.router.navigateByUrl('/app/analysis/' + (this.isGross ? 'gross' : 'net') + '/' + this.analysis.item);
    this.modal.hide();
  }
}

