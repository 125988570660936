import {Component, Input} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-title',
  template: `
    <div class="row">
      <div class="col-10">
        <h3>{{title}}</h3>
      </div>
      <div class="col-2">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  `,
})
export class ModalTitleComponent {

  @Input()
  title: string;

  constructor(private modal: BsModalRef) {
  }

  close() {
    this.modal.hide();
  }
}
