import {EventEmitter, Injectable} from '@angular/core';
import {InfoTopicMessage, InfoTopicNotification} from '../../../data/riesko/responses/info-topic-message';
import {RestApiService} from '../../../shared/rest-api.service';
import {from, Observable} from 'rxjs';
import {InfoTopicMessageRequest} from '../../../data/riesko/requests/info-topic-message-request';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private static _requestId = 0;
  private _currentConfiguration: MessagesServiceConfiguration = null;
  private _currentNodePath: string = null;
  private _visible = true;
  private _messages: InfoTopicMessage[] = [];
  private _messagesChangeEvent = new EventEmitter<InfoTopicMessage[]>();
  private _visibilityChangeEvent = new EventEmitter<boolean>();
  private _messageInviableChangeEvent = new EventEmitter<boolean>();

  public onNotificationsChanges: EventEmitter<NotificationData> = new EventEmitter<NotificationData>();


  constructor(private api: RestApiService
  ) {
  }

  get messages(): InfoTopicMessage[] {
    return this._messages;
  }

  // TODO: rimuovere il metodo di setter e utilizzare dei metodi per il caricamento progressivo diretto (usare this.api)
  set messages(messages: InfoTopicMessage[]) {
    this._messages = messages;
    this._messagesChangeEvent.emit(this._messages);
  }

  public set currentConfiguration(newCfg: MessagesServiceConfiguration) {
    this._currentConfiguration = newCfg;
    if (this._currentConfiguration == null) {
      this._messageInviableChangeEvent.emit(false);
    } else {
      const canSendMessage = this._currentConfiguration.item != null && this.currentConfiguration.item.trim().length > 0;
      this._messageInviableChangeEvent.emit(canSendMessage);
    }
  }

  public get currentConfiguration(): MessagesServiceConfiguration {
    return this._currentConfiguration;
  }

  onMessagesChanged(): EventEmitter<InfoTopicMessage[]> {
    return this._messagesChangeEvent;
  }

  onVisibilityChanged(): EventEmitter<boolean> {
    return this._visibilityChangeEvent;
  }

  onMessageInviableChanged(): EventEmitter<boolean> {
    return this._messageInviableChangeEvent;
  }

  setInfoTopicVisibility(state: boolean) {
    if (this._visible === state) {
      return;
    }
    this._visible = state;
    if (!this._visible) {
      this.currentConfiguration = null;
      this.messages = [];
    }
    this._visibilityChangeEvent.emit(this._visible);
  }

  updateMessages(currentNodePath: string, configuration: MessagesServiceConfiguration): Observable<InfoTopicMessage[]> {
    const promise = new Promise<InfoTopicMessage[]>(async (resolve) => {
      if (this.currentConfiguration != null &&
        (this.currentConfiguration.item !== configuration.item
          || this.currentConfiguration.type !== configuration.type
          || this.currentConfiguration.useNodePath !== configuration.useNodePath
          || this.currentConfiguration.fromId !== configuration.fromId
          || this.currentConfiguration.level !== configuration.level)
      ) {
        // clear messages
        this.messages = [];
      }
      this.currentConfiguration = configuration;
      this._currentNodePath = currentNodePath;

      if (configuration.item != null) {
        currentNodePath = null;
      }
      const requestId = ++MessagesService._requestId;
      const response = await this.api.getInfoTopic(currentNodePath, configuration.type, configuration.level,
        configuration.maxDate, configuration.item, configuration.fromId, configuration.minDate /* , beforeId? */);

      if (response.data?.oData?.notifications) {
        const data = new NotificationData(requestId, response.data.oData.notifications);
        this.onNotificationsChanges.emit(data);
      }

      if (requestId === MessagesService._requestId) {
        if (response.data?.oData?.aData != null) {
          const newMessages = response.data.oData.aData;
          this.mergeMessages(newMessages);
          return response.data.oData.aData;
        }
      }
      return null;
    });
    return from(promise);
  }

  private mergeMessages(newMessages: InfoTopicMessage[]) {
    const messagesToAdd: InfoTopicMessage[] = [];
    for (const message of newMessages) {
      const alreadyInSet = this.messages.find(x => x.id === message.id);
      if (!alreadyInSet) {
        messagesToAdd.push(message);
      }
    }
    if (messagesToAdd.length > 0) {
      // solo se sono presenti nuovi messaggi da mostrare
      this.messages = [...this.messages, ...messagesToAdd];
    } else {
      // this.messages = [...this.messages];
    }
  }

  async loadMoreMessages(beforeId: number) {
    const cfg = this.currentConfiguration;
    if (!cfg) {
      return;
    }
    const response = await this.api.getInfoTopic(this._currentNodePath, cfg.type, cfg.level, cfg.maxDate, cfg.item, cfg.fromId, cfg.minDate, beforeId);
    if (response.data?.oData?.aData != null) {
      const newMessages = response.data.oData.aData;
      this.messages = [...newMessages ?? [], ...this.messages];
      return response.data.oData.aData;
    }
    return null;
  }

  async sendMessage(message: string) {
    const cfg = this.currentConfiguration;
    if (cfg != null) {

      const response = await this.api.sendInfoTopicMessage(new InfoTopicMessageRequest(message), null, cfg.type,
        cfg.level, cfg.maxDate, cfg.item, cfg.fromId, cfg.minDate);
      // aggiorna i messaggi
      if (response.data?.oData?.aData != null) {
        this.messages = response.data.oData.aData;
        return response.data.oData.aData;
      }
      return null;
    }
    return null;
  }

  clearMessages() {
    // clear messages - attualmente richiamato al cambio della pagina da parte del RightBarComponent
    this.messages = [];
  }

}

export interface IMessagesServiceConfiguration {
  type?: string;
  level?: number;
  canSendMessages?: boolean;
  infoTopicMessagesVisible?: boolean;
  minDate?: string;
  maxDate?: string;
  item?: string;
  fromId?: number;
  useNodePath?: boolean;
  beforeId?: string;
  attachUploadPath?: string;
}

export class MessagesServiceConfiguration implements IMessagesServiceConfiguration {
  public type?: string;
  public level?: number;
  public canSendMessages?: boolean;
  public infoTopicMessagesVisible?: boolean;
  public minDate?: string;
  public maxDate?: string;
  public item?: string;
  public fromId?: number;
  public useNodePath?: boolean;
  public attachUploadPath = 'risk';

  // public beforeId?: string;

  constructor({
                minDate = null,
                maxDate = null,
                infoTopicMessagesVisible = true,
                canSendMessages = false,
                level = null,
                type = null,
                item = null,
                fromId = null,
                useNodePath = false,
                attachUploadPath = 'risk'
                // beforeId = null,
              }: IMessagesServiceConfiguration) {
    this.type = type;
    this.level = level;
    this.canSendMessages = canSendMessages;
    this.infoTopicMessagesVisible = infoTopicMessagesVisible;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.item = item;
    this.fromId = fromId;
    this.useNodePath = useNodePath;
    this.attachUploadPath = attachUploadPath;
    // this.beforeId = beforeId;
  }

}

export class NotificationData {
  requestId: number;
  notifications: InfoTopicNotification[];

  constructor(requestId: number, notifications: InfoTopicNotification[]) {
    this.requestId = requestId;
    this.notifications = notifications;
  }
}
