import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toTime'
})
export class ToTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const ts = value.toString();
    // TODO: implementare parser serio per estrarre la parte "temporale"
    if (!ts.includes(' ')) {
      return ts;
    }
    const time = ts.split(' ')[1];
    if (!time.includes(':')) {
      return time;
    }
    const times = time.split(':');
    return `${times[0]}:${times[1]}`;
  }

}
