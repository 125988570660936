export default {
  appLoginTitle: 'RIESKO RMG',
  errors: {
    generic: 'Si è verificato un erorre imprevisto',
    1: 'Nome utente o password errati',
    2: 'Utenza bloccata',
    3: 'Nessuna istanza collegata la tuo account',
    unauthorized: {
      title: 'Non autorizzato',
      message: 'Non hai i permessi per accedere a questa risorsa'
    }
  },
  general: {
    save: 'Salva',
    submit: 'Salva ed invia',
    delete: 'Elimina',
    cancel: 'Annulla',
    edit: 'Modifica',
    readOnly: 'Salva e apri in sola lettura',
    exit: 'Esci',
    saved: 'Salvataggio effettuato',
    'save-notification': 'Dati salvati correttamente',
    deleted: 'Eliminato',
    'delete-notification': 'Elemento eliminato correttamente',
    print: 'Stampa',
    'import': 'Import',
    'change-state': 'Cambia stato',
    'change-state-question': 'Sei sicuro di voler cambiare lo stato di ',
    allFieldsAreRequired: 'Tutti i campi sono obbligatori',
    questionDelete: 'Sei sicuro di voler eliminare l\'elemento "NAME"?',
    noName: '-',
    elementiPerPagina: 'Elementi per pagina',
    typeToSearch: 'Digita per cercare',
  },
  user: {
    'login-title': 'Accedi al sistema',
    'username-required': 'Nome utente richiesto',
    'password-required': 'Password richiesta',
    register: 'Registrazione',
    'forgot-password': 'Password dimenticata',
    email: 'E-mail',
    password: 'Password',
    'password-again': 'Password Again',
    'forgot-password-question': 'Password dimenticata?',
    fullname: 'Full Name',
    'login-button': 'Accedi',
    'register-button': 'Register',
    'reset-password-button': 'Reset',
    username: 'Nome utente',
    code: 'Reset Code',
    settings: 'Impostazioni',
    logout: 'Esci',
    login: 'Inserisci le credenziali per autenticarti. Se non sei un membro, procedi la ',
    wrongCredentials: 'Nome utente o password errati'
  },
  menu: {
    enterprises: 'Fornitori',
    resources: 'Risorse',
    language: 'Lingua',
    app: 'Home',
    start: 'Start',
    home: 'Home',
    identification: 'Rischi',
    analysis: 'Analisi',
    treatment: 'Trattamenti',
    gross: 'Analisi As Is',
    net: 'Analisi',
    search: 'Cerca',
    filter: 'Filtra',
    login: 'Accedi al sistema',
    register: 'Register',
    'forgot-password': 'Password dimenticata',
    'reset-password': 'Reset Password',
    error: 'Errore',
    menu: 'Menu',
    stats: 'Statistiche',
    admin: 'Amministrazione aziendale',
    budget: 'Valori di bilancio',
    library: 'Impostazioni azienda',
    import: 'Import Rischi',
    dossier: 'Dossier',
    esg: 'Esg',
    dashboard: 'Dashboard'
  },
  states: {
    tutti: 'Tutti',
    nonAnalizzato: 'Non analizzato',
    asIsNonAnalizzato: 'As Is non analizzato',
    proposto: 'Proposto',
    propostoTxt: 'Rischio in proposta, visibile solo a proprietario',
    inApprovazione: 'In approvazione',
    inApprovazioneTxt: 'Rischio in approvazione nella organizzazione',
    working: 'In lavorazione',
    workingTxt: 'Analisi in lavorazione',
    rifiutato: 'Rifiutato',
    rifiutatoTxt: 'Rischio rifiutato',
    rifiutatoTrattamentoTxt: 'Tratttamento rifiutato, costo NON computato, non utilizzabile in Analisi',
    rifiutatoAnalisiTxt: 'Analisi rifiutata',
    attivo: 'Attivo',
    attivoTxt: 'Rischio attivo ed analizzabile',
    attivoTrattamentoTxt: 'Trattamento in uso. Passaggio in automatico, alla attivazione della prima Analisi che lo utilizza',
    attivoAnalisiTxt: 'Analisi corrente per il Rischio',
    nuovo: 'Nuovo',
    nuovoTxt: 'Analisi in bozza, modificabile da tutti e da sottomettere alla approvazione del Risk Manager',
    superato: 'Superato',
    superatoTxt: 'Analisi obsoleta, sostituita da nuova Analisi attiva',
    bozza: 'Bozza',
    bozzaTxt: 'Bozza trattamento, modificabile. Costo NON computato',
    approvato: 'Approvato',
    approvatoTxt: 'Analisi approvata dal Risk Manager, e da sottomettere alla approvazione del Risk Owner di livello superiore',
    approvatoTrattamentoTxt: 'Trattamento approvato da RM, costo computato, utilizzabile in Analisi',
    scaduto: 'Scaduto',
    scadutoTxt: 'Trattamento scaduto. Passaggio in automatico, alla data di fine. Costo NON computato',
    inattivo: 'Inattivo',
    inattivoTxt: 'Rischio non attivo, non analizzabile',
    compilazione: 'Compilazione',
    compilazioneProcessoTxt: 'Processo in compilazione',
    bozzaProcessoTxt: 'Processo in proposta',
    attivoProcessoTxt: 'Processo attivo'
  },
  analysis: {
    stato: 'Stato',
    rischio: 'Rischio',
    analisi: 'Analisi',
    attivatoIl: 'Attivato il',
    scadeIl: 'Scade il',
    localizzazione: 'Localizzazione',
    valutazione: 'Valutazione',
    azioni: 'Azioni',
    warnings: 'Warnings',
    'causes-tab': {
      cause: 'Causa',
      effect: 'Effetto',
      impact: 'Impatto',
      conseguence: 'Conseguenza',
      probability: 'Probabilità',
      description: 'Descrizione'
    }
  },
  risks: {
    risk: 'Rischio',
  },
  supplier: {
    name: 'Nome',
    'fiscal-code': 'Codice fiscale',
    piva: 'Partita Iva',
    www: 'Website',
    nation: 'Nazione',
    province: 'Provincia',
    city: 'Città',
    zip: 'CAP',
    pec: 'Pec',
    address: 'Indirizzo',
    mail: 'E-mail',
    addActivity: 'Aggiungi attività',
    activities: 'Lista delle attività',
    activity: 'Attività',
    details: 'Dettagli'
  },
  treatments: {
    stato: 'Stato',
    rischio: 'Rischio',
    trattamento: 'Trattamento',
    strategia: 'Strategia',
    attivazione: 'Attivazione',
    scadenza: 'Scadenza',
    localizzazione: 'Localizzazione',
    azioni: 'Azioni',
  },
  bowtieinterface: {
  },
  bia: {
    restorePlains: 'Piani di ripristino',
    addPlan: 'Aggiungi Piano di Ripristino',
    associatedPlan: 'Piano di Ripristino Associato',
    noAssociatedPlan: 'Nessun Piano di Ripristino Associato',
    chageStateError: 'Impossibile cambiare stato. Vincolo non rispettato per i seguenti processi:',
    nuovoProdottoServizio: 'Nuovo Prodotto/Servizio',
    cambioDiStato: 'Cambio di stato',
    selezionaProcesso: 'Seleziona il processo',
    tipo: 'Tipo',
    processes: 'Processi',
    process: 'Processo',
    subProcesses: 'Sotto processi',
    subProcess: 'Sotto processo',
    activity: 'Attività',
    subProcessesActivity: 'Sotto processi / Attività',
    subProcessActivity: 'Sotto processo / Attività',
    tools: 'Strumenti',
    'confirm-delete': 'Conferma elimination',
    'question-delete': 'Sei sicuro di voter cambiare eliminare l\'elemento?',
    'select-supplier': 'Seleziona il fornitore',
    associations: {
      procedure: 'Procedura',
      description: 'Descrizione',
    },
    warnings: {
      top: '',
      bottom: '',
    },

    supplierData: {
      selezionaAttrezzatura: 'Seleziona l\'attrezzatura',
      selezionaFornitore: 'Seleziona il fornitore',
      selezionaRisorsa: 'Seleziona una risorsa',
      nome: 'Nome',
      city: 'Città',
      selezionaFornitura: 'Seleziona fornitura',
      fornitura: 'Fornitura',
      livello: 'Livello',
      tipo: 'Tipo',
      nScorta: 'N. scorta',
      nFrequenza: 'N. freq',
      aumento: 'Aumento',
      impatto: 'Impatto',
      note: 'Note',
      bloccante: 'Bloccante',
      nuovaFornitura: 'Nuova fornitura',
      nuovaAttrezzatura: 'Nuova attrezzatura',
    },
    siti: {
      sito: 'Sito',
      nuovoSito: 'Nuovo sito',
      descrizione: 'Descrizione',
      cap: 'Cap',
      citta: 'Città',
      nomenclatura: 'Nomenclatura',
      paese: 'Paese',
      via: 'Via',
      unita: 'Unità'
    },
    personale: {
      nRisorseNecessarie: 'N. risorse necessarie',
      nRisorseEmergenza: 'N. risorse di emergenza',
      referente: 'Referente',
      risorseSostitute: 'Risorse sostitute',
      risorseEmergenza: 'Risorse in emergenza',

    },
    resource: {
      nome: 'Nome',
      cognome: 'Cognome',
      competenza: 'Competenza',
      telefonoPrincipale: 'Telefono principale',
      cellularePrincipale: 'Cellulare principale',
      emailPrincipale: 'Email principale',
      telefono: 'Telefono',
      cellulare: 'Cellulare',
      email: 'Email',
      lavoraDaRemoto: 'Lavora da remoto',
      interno: 'Interno',
    },
    timeClass: {
      a: 'Fino a 2 ore',
      b: '2 - 4 ore',
      c: '4 - 8 ore',
      d: '8 - 24 ore',
      e: '24 ore - 2 giorni',
      f: '2 giorni - 5 giorni',
      g: '5 giorni - 8 giorni',
      h: '8 giorni - 15 giorni',
      i: 'oltre 15 giorni'
    },
    volumesTab: {
      codice: 'Codice',
      nOperazioni: 'N. Operazioni',
      importoMedio: 'Importo medio',
      periodoRiferimento: 'Periodo di riferimento',
      tools: 'Strumenti',
      periodoUM: 'Periodo UM',
      nPeriodo: 'N. Periodo',
    },
    interdipTab: {
      stato: 'Stato',
      processoCritico: 'Processo Critico',
      processoNonCritico: 'Processo Non Critico',
      gradiInterdip: 'Gradi Interdip.',
      gradiInterdipendenza: 'Gradi di interdipendenza',
      descrizione: 'Descrizione',
      input: 'Input',
      output: 'Output',
      bloccante: 'Bloccante',
      tools: 'Strumenti',
      tempisticaBloccante: 'Tempistica bloccante',
      nBloccanti: 'N. bloccanti',
      note: 'Note',
    },
    'general-tab': {
      servizio: 'Servizio',
      prodotto: 'Prodotto',
      normative: 'Noramtiva',
      title: 'Titolo'
    },
    supplierTab: {
      state: 'Stato',
      name: 'Nome',
      fornitura: 'Fornitura',
      livello: 'Livello',
      strumenti: 'Strumenti'
    }
  },
  metrics: {
    coefficenteRischio: 'Cofficente di rischio',
    giorniLavorativiAnno: 'Giorni lavorativi per anno',
    add: 'Aggiungi',
    totals: 'TOTALI',
    vociBilancio: 'Voci di bilancio',
    valore: 'Valore',
    percentuale: 'Minimo non eliminabile',
    importoPositivo: 'Importo positivo',
    importoNegativo: 'Importo negativo',
    dannoInterruzione: 'Danno da interruzione attività totale',
    dannoGiornalieroEvento: 'Danno giornaliero in caso di evento',
    impattoTollerabile: 'Impatto tollerabile'
  },
  adminRoles: {
    dettagli: 'Dettagli',
    moduli: 'Moduli',
    policies: 'Policies',
    add: 'Aggiungi',
    newRole: 'Nuovo ruolo',
    inputNewRoleCode: 'Inserisci il codice del ruolo',
    newRoleCode: 'Codice ruolo',
    roleCode: 'Codice ruolo',
    roleName: 'Nome ruolo',
    policy: {
      item: 'Item',
      desc: 'Descrizione'
    }
  },
  adminNodes: {
    dettagli: 'Dettagli',
    ruoli: 'Ruoli',
    svuota: 'Svuota',
    cleared: 'Svuotato',
    add: 'Aggiungi',
    newNode: 'Nuovo nodo',
    newRole: 'Nuovo ruolo',
    renameNode: 'Rinomina nodo',
    inputNewNodeCode: 'Inserisci il codice del nodo',
    newNodeCode: 'Codice nodo',
    'cleared-notification': 'Nodo svuotato',
    node: {
      id: 'Id',
      name: 'Nome',
      description: 'Descrizione',
      path: 'Path',
      code: 'Codice',
      tollerance: 'Tolleranza',
      appetite: 'Appetite',
      cbs: 'CBS'
    },
    roles: {
      id: 'Id',
      code: 'Codice',
      description: 'Descrizione',
    }
  },
  issues: {
    createCampaign: 'Nuova campagna',
    create: 'Nuova issue',
    title: 'Titolo',
    survey: 'Survey',
    mode: 'Accesso',
    target: 'Target',
    nodes: 'Nodi',
    state: 'Stato',
    situation: 'Situazione',
    actions: 'Azioni',
    tollerance: 'Tolleranza',
    appetite: 'Appetite',
    changeState: 'Cambia stato',
    prevDateStart: 'prevDateStart',
    prevDateEnd: 'prevDateEnd',
    effDateStart: 'effDateFrom',
    effDateEnd: 'effDateEnd',
  },
  monitors: {
    status: 'Stato',
    type: 'Tipo',
    origin: 'Origine',
    date: 'Data',
    actions: 'Azioni',
    next: 'Avanti',
    prev: 'Indietro',
    warning: 'Attenzione',
    requiredValues: 'Devi inserire tutti i valori richiesti'
  },
  plannings: {
    create: 'Crea',
    status: 'Stato',
    description: 'Descrizione',
    title: 'Titolo',
    addElement: 'Aggiungi attività',
    listElements: 'Lista attività',
    items: {
      element: 'Attività',
      title: 'Titolo',
      description: 'Descrizione',
      tabs: 'Tabs',
    },
    statusList: {
      draft: 'Bozza',
      approving: 'Approvazione',
      active: 'Attivo',
      all: 'Tutti'
    },
    associations: {
      associate: 'Associa',
      description: 'Descrizione',
      note: 'Note',
      associated: 'Associato',
      details: 'Dettagli associazione'
    },
    tabs: {
      general: 'Generale',
      attachments: 'Allegati',
      associations: 'Associazioni',
    }
  },
  surveys: {
    pageTitle: 'Surverys',
    create: 'Crea Survey',
    status: 'Stato',
    name: 'Nome',
    actions: 'Azioni',
    order: 'Ordinamento',
    answer: 'Risposta',
    ownerId: 'OwnerId',
    targetType: 'TargetType',
    targetId: 'TargetId',
    action: 'Azione',
    value: 'Valore',
    addSection: 'Aggiungi sezione',
    addQuestion: 'Aggiungi quesito',
    addAnswer: 'Aggiungi risposta',
    title: 'Title',
    moveUp: 'Sposta su',
    moveDown: 'Sposta giù',
    deleteSection: 'Elimina sezione',
    deleteQuestion: 'Elimina quesito',
  },
  esg: {
    issues: 'Issues',
    noIssues: 'Nessun problema rilevato',
    company: 'Azienda',
    dashboard: {
      state: 'Stato',
      companyName: 'Nome azienda',
      actions: 'Azioni',
      prog: 'Prog',
      validFrom: 'Valido dal',
      validTo: 'Valido al',
    },
    valuechain: {
      settore: 'Settore',
      geografico: 'Geografico',
      prodotto: 'Prodotto',
      aggiungiElemento: 'Aggiungi nuovo elemento',
      segModal: {
        title: 'Selettore scenario',
        S: 'S',
        E: 'E',
        G: 'G',
        scenario: 'Scenario',
        riskIndex: 'Indice di rischio',
        actions: 'Azioni'
      }
    },
    noData: 'Nessun dato'
  },
  lookup: {
    code: 'Codice',
    parent: 'Padre',
    name: 'Nome',
    note: 'Note',
    description: 'Descrizione',
  }
};
