import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {SidebarService, ISidebar} from './sidebar.service';
// import menuItems, { IMenuItem } from 'src/app/constants/menu';
import {Subject, Subscription} from 'rxjs';
import menu, {IMenuItem} from '../../../constants/menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {

  static selectedParentMenuId = null;
  static viewingParentMenuId = null;
  static viewingParentMenu = '';

  private destroyEvent: Subject<boolean> = new Subject<boolean>();

  // menuItems: IMenuItem[] = []; // TODO: Per popolare il menu dinamicamente creare un servizio che ottiene i dati del menu...
  get menuItems(): IMenuItem[] {
    return this.sidebarService.menuItems;
  }

  selectedParentMenu = '';
  currentUrl: string;

  sidebar: ISidebar;
  subscription: Subscription;
  subscriptionMenuLoaded: Subscription;

  constructor(private router: Router, private sidebarService: SidebarService, private activatedRoute: ActivatedRoute) {
    this.subscriptionMenuLoaded = this.sidebarService.menuLoaded.subscribe(() => {
      this.isCurrentMenuHasSubItem();
    });
    this.subscription = this.sidebarService.getSidebar()
      // .pipe(takeUntil(this.destroyEvent))
      .subscribe(
        res => {
          this.sidebar = res;
        },
        err => {
          console.error(`An error occurred: ${err.message}`);
        }
      );
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      ).subscribe((event) => {
      const path = this.router.url.split('?')[0];
      const paramtersLen = Object.keys(event.snapshot.params).length;
      const pathArr = path.split('/').slice(0, path.split('/').length - paramtersLen);
      this.currentUrl = pathArr.join('/');
    });

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const {containerClassnames} = this.sidebar;
      // const toParentUrl = this.currentUrl.split('/').filter(x => x !== '')[1];
      // if (toParentUrl !== undefined && toParentUrl !== null) {
      //   this.selectedParentMenu = toParentUrl.toLowerCase();
      // } else {
      //   this.selectedParentMenu = 'dashboards';
      // }
      this.selectMenu();
      this.toggle();
      this.sidebarService.setContainerClassnames(0, containerClassnames, this.sidebar.selectedMenuHasSubItems);
      window.scrollTo(0, 0);
    });

    // reimposta il menu selezionato ottenendo il menuId dal cmapo menuId di Data di Routing
    router.events.pipe(
      filter(evt => evt instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data),
    ).subscribe(data => {
      if (data.menuId && !SidebarComponent.selectedParentMenuId) {
        SidebarComponent.selectedParentMenuId = data.menuId;
      }
    });

    // activatedRoute.data
    // //   .pipe(
    // //   // takeUntil(this.destroyEvent),
    // //   // map(results => ({results: results}))
    // // )
    //   .subscribe((data) => {
    //     const menuId = data.menuId;
    //     const gross = data.gross;
    //     console.log({gross});
    //     console.log({menuId});
    //   }
    // );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.selectMenu();
      const {containerClassnames} = this.sidebar;
      const nextClasses = this.getMenuClassesForResize(containerClassnames);
      this.sidebarService.setContainerClassnames(0, nextClasses.join(' '), this.sidebar.selectedMenuHasSubItems);
      this.isCurrentMenuHasSubItem();
    }, 100);
  }

  toggleSottoMenu() {
    this.toggle();
  }

  apriSottoMenu() {
    if (SidebarComponent.selectedParentMenuId !== null) {
      const menuItem = this.menuItems.find(item => item.id === SidebarComponent.selectedParentMenuId);
      this.openSubMenu(null, menuItem);
    }
  }

  ngOnDestroy(): void {
    if (this.destroyEvent) {
      this.destroyEvent.next(true);
      this.destroyEvent.unsubscribe();
      this.destroyEvent = null;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    if (this.subscriptionMenuLoaded) {
      this.subscriptionMenuLoaded.unsubscribe();
      this.subscriptionMenuLoaded = null;
    }
  }

  selectMenu() {
    this.selectedParentMenu = this.currentUrl;
    // const currentParentUrl = this.currentUrl.split('/').filter(x => x !== '')[1];
    // if (currentParentUrl !== undefined || currentParentUrl !== null) {
    //   this.selectedParentMenu = currentParentUrl.toLowerCase();
    // } else {
    //   this.selectedParentMenu = '/app/home';
    // }
    this.isCurrentMenuHasSubItem();
  }

  isCurrentMenuHasSubItem() {
    const {containerClassnames} = this.sidebar;

    const menuItem = this.menuItems.find(
      x => x.id === this.selectedParentMenu
    );
    // if (menuItem === undefined || this.menuItems.length === 0) {
    //   return false;
    // }
    const isCurrentMenuHasSubItem =
      menuItem && menuItem.subs && menuItem.subs.length > 0 ? true : false;
    if (isCurrentMenuHasSubItem !== this.sidebar.selectedMenuHasSubItems) {
      if (!isCurrentMenuHasSubItem) {
        this.sidebarService.setContainerClassnames(0, containerClassnames, false);
      } else {
        this.sidebarService.setContainerClassnames(0, containerClassnames, true);
      }
    }
    return isCurrentMenuHasSubItem;
  }

  changeSelectedParentHasNoSubmenu(parentMenu: string) {
    const {containerClassnames} = this.sidebar;
    this.selectedParentMenu = parentMenu;
    SidebarComponent.selectedParentMenuId = parentMenu;
    SidebarComponent.viewingParentMenu = parentMenu;
    SidebarComponent.viewingParentMenuId = parentMenu;
    this.sidebarService.changeSelectedMenuHasSubItems(false);
    this.sidebarService.setContainerClassnames(0, containerClassnames, false);
  }

  isActive(item: IMenuItem) {
    return item.to == this.currentUrl;
  }

  isInPath(item: IMenuItem) {
    return (SidebarComponent.selectedParentMenuId === item.id && SidebarComponent.viewingParentMenuId === null) || SidebarComponent.viewingParentMenuId === item.id;
  }


  openSubMenu(event: { stopPropagation: () => void; }, menuItem: IMenuItem) {
    if (event) {
      event.stopPropagation();
    }
    const {containerClassnames, menuClickCount} = this.sidebar;
    if (!menuItem) {
      return;
    }

    const selectedParent = menuItem.id;
    const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
    this.sidebarService.changeSelectedMenuHasSubItems(hasSubMenu);
    if (!hasSubMenu) {
      SidebarComponent.viewingParentMenuId = selectedParent;
      this.selectedParentMenu = selectedParent;
      this.toggle();
    } else {
      const currentClasses = containerClassnames ?
        containerClassnames.split(' ').filter(x => x !== '') :
        '';

      if (!currentClasses.includes('menu-mobile')) {
        if (
          currentClasses.includes('menu-sub-hidden') &&
          (menuClickCount === 2 || menuClickCount === 0)
        ) {
          this.sidebarService.setContainerClassnames(3, containerClassnames, hasSubMenu);
        } else if (
          currentClasses.includes('menu-hidden') &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
          this.sidebarService.setContainerClassnames(2, containerClassnames, hasSubMenu);
        } else if (
          currentClasses.includes('menu-default') &&
          !currentClasses.includes('menu-sub-hidden') &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
          this.sidebarService.setContainerClassnames(0, containerClassnames, hasSubMenu);
        }
      } else {
        this.sidebarService.addContainerClassname('sub-show-temporary', containerClassnames);
      }
      SidebarComponent.viewingParentMenuId = selectedParent;
    }
  }

  toggle() {
    const {containerClassnames, menuClickCount} = this.sidebar;
    const currentClasses = containerClassnames.split(' ').filter(x => x !== '');
    if (
      currentClasses.includes('menu-sub-hidden') &&
      menuClickCount === 3
    ) {
      this.sidebarService.setContainerClassnames(2, containerClassnames, this.sidebar.selectedMenuHasSubItems);
    } else if (
      currentClasses.includes('menu-hidden') ||
      currentClasses.includes('menu-mobile')
    ) {
      if (!(menuClickCount === 1 && !this.sidebar.selectedMenuHasSubItems)) {
        this.sidebarService.setContainerClassnames(0, containerClassnames, this.sidebar.selectedMenuHasSubItems);
      }
    }
  }

  getMenuClassesForResize(classes: string) {
    let nextClasses = classes.split(' ').filter((x: string) => x !== '');
    const windowWidth = window.innerWidth;

    if (windowWidth < this.sidebarService.menuHiddenBreakpoint) {
      nextClasses.push('menu-mobile');
    } else if (windowWidth < this.sidebarService.subHiddenBreakpoint) {
      nextClasses = nextClasses.filter((x: string) => x !== 'menu-mobile');
      if (
        nextClasses.includes('menu-default') &&
        !nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses.push('menu-sub-hidden');
      }
    } else {
      nextClasses = nextClasses.filter((x: string) => x !== 'menu-mobile');
      if (
        nextClasses.includes('menu-default') &&
        nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses = nextClasses.filter((x: string) => x !== 'menu-sub-hidden');
      }
    }
    return nextClasses;
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event) {
    SidebarComponent.viewingParentMenu = '';
    this.selectMenu();
    this.toggle();
  }

  @HostListener('window:resize', ['$event'])
  handleWindowResize(event) {
    if (event && !event.isTrusted) {
      return;
    }
    const {containerClassnames} = this.sidebar;
    const nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.sidebarService.setContainerClassnames(0, nextClasses.join(' '), this.sidebar.selectedMenuHasSubItems);
    this.isCurrentMenuHasSubItem();
  }

  menuClicked(e: MouseEvent) {
    e.stopPropagation();
  }
}
