import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Translations} from '../services/translations.service';
import {BiaProcessLevel} from '../data/riesko/responses/bia-responses';
import { TipoBiaService } from '../services/tipo-bia.service';

@Pipe({
  name: 'tipoBia'
})
export class TipoBiaPipe implements PipeTransform {

  constructor(private tipoBiaService: TipoBiaService) {
  }

  transform(value: any, isService?: any): any {
    return this.tipoBiaService.transform(value, isService);
  }

}
