import {Component, Input} from '@angular/core';
import {Treatment} from '../../../data/riesko/treatment';
import {formatNumberLanguage} from '../../../shared/utility-functions';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-treatment-economics-info',
  templateUrl: 'treatment-economics-info.component.html'
})
export class TreatmentEconomicsInfoComponent {
  @Input()
  treatment: Treatment;

  constructor(private translateService: TranslateService) {
  }

  public getReferer(): string {
    const selected = this.treatment.referer;
    if (selected != null) {
      return selected.sName;
    }
    return '';
  }

  getType(): string {
    return this.treatment?.tipo.find(x => x.selected)?.desc ?? '';
  }

  getCost(): string {
    return formatNumberLanguage(this.treatment.cost, this.translateService.currentLang);
  }
}
