import {Component, Input} from '@angular/core';
import {RiskAttachment} from '../../../data/riesko/risk';
import {environment} from '../../../../environments/environment';
import {RestApiService} from '../../../shared/rest-api.service';
import {VItem} from '../../../data/riesko/responses/v-item';
import {Response} from 'ts-retrofit';
import {GenericResponse} from '../../../data/riesko/generic-response';

@Component({
  selector: 'app-risk-attachments-info',
  templateUrl: './risk-attachments-information.component.html',
  styleUrls: ['./risk-attachments-information.component.css'],
})
export class RiskAttachmentsInformationComponent {
  @Input()
  attachContainer: VItem;

  @Input()
  uploadPath = 'risk';

  @Input()
  canEdit = false;

  @Input()
  subOwner?: string;

  uploadingFiles: File[] = [];
  uploading = false;

  get attachContainerId(): string | number {
    return this.attachContainer.itemID
      ?? this.attachContainer.item
      ?? this.attachContainer.ID
      ?? this.attachContainer.id;
  }

  constructor(private api: RestApiService) {
  }

  get hasAttachments(): boolean {
    return this.getAttachments().length > 0;
  }

  getFilenameWithExt(attach: RiskAttachment): string {
    return attach.sNam;
  }

  onSelect(event) {
    this.uploadingFiles.push(...event.addedFiles);
    if (!this.uploading) {
      this.uploadFiles();
    } else {
      // il seguente controllo ritardato serve per evitare situazioni di upload file che potrebbero venire ignorate
      setTimeout(() => {
        if (!this.uploading) {
          this.uploadFiles();
        }
      }, 200);
    }
  }

  onRemove(event) {
    this.uploadingFiles.splice(this.uploadingFiles.indexOf(event), 1);
  }

  async uploadFiles() {
    this.uploading = true;
    if (this.uploadingFiles.length > 0) {
      const reader = new FileReader();
      const file = this.uploadingFiles[0];
      reader.onload = async () => {
        let base64fileContent = reader.result as string;

        // remove the prefix "data:*/*;base64,"
        const prefix = `data:${file.type};base64,`;
        base64fileContent = base64fileContent.substring(prefix.length);

        let result: Response<GenericResponse<{ attach: RiskAttachment[] }>>;
        if (this.subOwner) {
          result = await this.api.uploadItemFileWithSubOwner(
            this.uploadPath,
            this.attachContainerId,
            {filename: file.name, file64: base64fileContent},
            this.subOwner
          );
        } else {
          result = await this.api.uploadItemFile(
            this.uploadPath,
            this.attachContainerId,
            {filename: file.name, file64: base64fileContent},
          );
        }

        if (result.data.bSuccess) {
          console.log(result.data.oData);
          this.attachContainer.attach = result.data.oData.attach;
        }

        this.uploadingFiles.shift(); // remove first element
        if (this.uploadingFiles.length > 0) {
          this.uploadFiles();
        } else {
          this.uploading = false;
        }
      };
      reader.readAsDataURL(file); // automatically encode as base64
    }
  }

  async secureDownloadAllFiles() {
    let response: Response<GenericResponse<string>>;
    if (this.subOwner) {
      response = await this.api.secureDownloadAllFileWithSubOwner(
        this.uploadPath,
        this.attachContainer.itemID,
        this.subOwner
      );
    } else {
      response = await this.api.secureDownloadAllFile(
        this.uploadPath,
        this.attachContainer.itemID,
      );
    }

    const link = response.data.oData;
    if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      window.open(`${environment.apiUrl}${link}`, '_blank');
    }
  }


  getAttachments() {
    if (!this.attachContainer?.attach) {
      return [];
    }
    if (this.subOwner) {
      return this.attachContainer.attach.filter(x => x.sub === this.subOwner);
    }
    return this.attachContainer.attach;
  }
}
