import {Component, Input} from '@angular/core';
import {Risk} from '../../data/riesko/risk';
import {NodeSelectorService} from '../../containers/layout/topnav/node-selector.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Translations} from '../../services/translations.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-risk-details',
  templateUrl: './risk-details.component.html'
})
export class RiskDetailsComponent {

  @Input()
  risk: Risk;

  constructor(public translations: Translations,
              private nodeSelector: NodeSelectorService,
              private router: Router,
              private modal: BsModalRef) {
  }

  getRiskValutatedAtTabTitle(): string {
    return this.nodeSelector.currentNode.sLabel;
  }

  close(): void {
    this.modal.hide();
  }

  open() {
    this.router.navigateByUrl('/app/identification/' + this.risk.itemID);
    this.modal.hide();
  }
}

