<table class="table table-striped table-hover" *ngIf="treatment != null">
  <tr *ngIf="treatment.risk?.stato != null && treatment.risk.stato.length > 0">
    <th>{{'treatments.economics-tab.responsable'|translate}}</th>
    <td>{{getReferer()}}</td>
  </tr>
  <tr>
    <th>{{'treatments.economics-tab.type'|translate}}</th>
    <td>{{getType()}}</td>
  </tr>
  <tr>
    <th>{{'treatments.economics-tab.start-date'|translate}}</th>
    <td>{{treatment.dInizio?.dis}}</td>
  </tr>
  <tr>
    <th>{{'treatments.economics-tab.end-date'|translate}}</th>
    <td>{{treatment.dFine?.dis}}</td>
  </tr>
  <tr>
    <th>{{'treatments.economics-tab.cost'|translate}}</th>
    <td><span [innerHTML]="getCost() + '€'"></span></td>
  </tr>
</table>
