import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeHtmlEntities'
})
export class DecodeHtmlEntitiesPipe implements PipeTransform {
  transform(value: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = value;
    return txt.value;
  }
}
