import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InfoTopicMessage} from '../../../data/riesko/responses/info-topic-message';
import {MessagesService} from './messages.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {PerfectScrollbarComponent, PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {environment} from '../../../../environments/environment';
import {StorageService} from '../../../shared/storage.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {InspectorService} from '../../../services/inspector.service';

@Component({
  selector: 'app-rightbar',
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.css']
})
export class RightbarComponent implements OnInit, OnDestroy, AfterViewInit {

  // ====================================================================
  //      FIELDS
  // ====================================================================
  private _destroy$ = new Subject<void>();

  _lastDay: string = null;
  /* Se impostato a true deve impostare il margine destro del tag <main> a 290px. Si puo` fare aggiungendo/rimuvendo una classe al tale */
  pinnedBar = false;
  closed = true;
  visible = true;
  canSendMessage = false;
  chatMessage = '';
  messages: InfoTopicMessage[] = [];
  @ViewChild('scrollbar') scrollbar: PerfectScrollbarComponent;

  get riskItemId(): string {
    return this.messagesService.currentConfiguration.fromId?.toString() ?? '';
  }

  // ====================================================================
  //      CONSTRUCTOR
  // ====================================================================

  constructor(private messagesService: MessagesService, private storageService: StorageService,
              private inspectorService: InspectorService,
              private router: Router) {
    // Al cambio della pagina (URL) azzeriamo i messaggi del WORKGROUP
    router.events.pipe(
      filter(event => event instanceof NavigationStart),
      takeUntil(this._destroy$)
    ).subscribe((event: NavigationEnd) => {
      this.messagesService.clearMessages();
    });
  }

  ngOnInit(): void {
    this.messagesService.onMessagesChanged().subscribe((messages: InfoTopicMessage[]) => {
      this._lastDay = null;
      this.messages = this.messagesService.messages;
      // viene eseguito dopo 20ms per garantire che il DOM si sia aggiornato con i dati
      setTimeout(() => {
        this.scrollbar?.directiveRef?.scrollToBottom();
      }, 20);
    });
    this.messagesService.onVisibilityChanged().subscribe((visibilityState: boolean) => {
      this.visible = visibilityState;
    });
    this.messagesService.onMessageInviableChanged().subscribe((canSendMessage: boolean) => {
      this.canSendMessage = canSendMessage;
    });

    this.pinnedBar = this.storageService.stickyRightBar;
    this.closed = !this.pinnedBar;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit(): void {
    if (this.pinnedBar) {
      setTimeout(() => {
        this.onChangePinnedBar();
        if (!this.visible) {
          this.unpinRightBar();
        }
      }, 200);
    }
  }

  // ====================================================================
  //      GET & SET
  // ====================================================================
  getLastDay(): string {
    return this._lastDay;
  }

  // ====================================================================
  //      METHODS
  // ====================================================================

  checkSeparatorTime(message: InfoTopicMessage) {
    const firstMessage = this.messages.indexOf(message) === 0;    // fix per doppia chiamata

    const ts = message?.tstamp;
    if (ts && ts.includes(' ')) {
      const day = ts.split(' ')[0];
      if (day !== this._lastDay || firstMessage) {
        this._lastDay = day;
        return true;
      }
    }
    return false;
  }

  onChangePinnedBar() {
    console.log('toggle changed');
    this.storageService.stickyRightBar = this.pinnedBar;
    this.updatePinnedBar();
  }

  updatePinnedBar() {
    if (this.pinnedBar) {
      this.pinRightBar();
    } else {
      this.unpinRightBar();
    }

    // genera "resize event" per permettere al contenuto di adeguarsi
    // setTimeout(() => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', false, false);
    window.dispatchEvent(event);
    // }, 20);
  }

  private pinRightBar() {
    const main = document.getElementsByTagName('main')[0];
    main.classList.add('right-bar-fixed'); // riga 2861 dell'scss
  }

  private unpinRightBar() {
    const main = document.getElementsByTagName('main')[0];
    main.classList.remove('right-bar-fixed'); // riga 2861 dell'scss
  }

  openMenu() {
    this.closed = false;
  }

  closeMenu() {
    if (!this.pinnedBar) {
      this.closed = true;
    }
  }

  isSuccessClass(cls: string): boolean {
    return cls?.includes('badge-success');
  }

  isFailureClass(cls: string): boolean {
    return cls?.includes('badge-warning') || cls?.includes('badge-error');
  }

  async sendNewChatMessage() {
    if (this.chatMessage.trim().length === 0) {
      // show error
      return;
    }
    const chatMsgToSend = this.chatMessage.trim();
    const response = await this.messagesService.sendMessage(chatMsgToSend);
    this.chatMessage = '';
    // TODO aggiungere un "loading" (per connessioni lente?)
  }

  async loadMore() {
    await this.messagesService.loadMoreMessages(this.messages[0].id);
    // TODO aggiungere un "loading" (per connessioni lente?)
  }

  getRootPath() {
    return this.messagesService.currentConfiguration.attachUploadPath;
  }

  getRiskItemId(message: InfoTopicMessage) {
    return message.oObject.sItem;
  }

  openInspector(message: InfoTopicMessage) {
    if (message.body.type === 'upload') {
      return;
    }
    const type = message.oObject.sItemType === 'RSK' ? 'Risk' :
      message.oObject.sItemType === 'TRT' ? 'Treatment' :
        'Analysis';
    this.inspectorService.open({
      type,
      analysisType: type === 'Analysis' ? (message.oObject.isGross ? 'gross' : 'net') : undefined,
      id: message.oObject.sItem,
      rawData: null
    });
  }
}
