<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
       href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1"/>
        <rect x="0.48" y="7.5" width="7" height="1"/>
        <rect x="0.48" y="15.5" width="7" height="1"/>
      </svg>
      {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1"/>
        <rect x="1.56" y="7.5" width="16" height="1"/>
        <rect x="1.56" y="15.5" width="16" height="1"/>
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
       class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1"/>
        <rect x="0.5" y="7.5" width="25" height="1"/>
        <rect x="0.5" y="15.5" width="25" height="1"/>
      </svg>
    </a>

    <!-- TODO: nascosto: barra di ricerca (search)
    <div class="search" (click)="searchAreaClick($event)">
      <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div>
    -->

    <!-- TODO: spostato: selettore lingua
    <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
      <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
        {{currentLanguage | uppercase}} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item" href="javascript:;">{{item.label}}</a></li>
      </ul>
    </div>
    -->
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>

  </div>

  <!--
  <a class="navbar-logo" href="/">
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a>
  -->
  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <!-- TODO: spostato: dark mode
      <div class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive" (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>
      -->

      <!-- TODO: nascosto: pulsante dei componenti (4 quadrati)
      <div class="position-relative d-none d-sm-inline-block">
        <div dropdown>
          <button  dropdownToggle type="button" appClickStopPropagation class="header-icon btn btn-empty">
            <i class="simple-icon-grid"></i>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic" id="iconMenuDropdown">
            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-equalizer d-block"></i>
              <span>Settings</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-male-female d-block"></i>
              <span>Users</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-puzzle d-block"></i>
              <span>Components</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-bar-chart-4 d-block"></i>
              <span>Profits</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-file d-block"></i>
              <span>Surveys</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-suitcase d-block"></i>
              <span>Tasks</span>
            </a>
          </div>
        </div>
      </div>
      -->

      <div *ngIf="notifications.length>0" class="position-relative d-inline-block">
        <div dropdown>
          <button id="button-basic" dropdownToggle type="button" id="notificationButton"
                  class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span *ngIf="this.unreadCount>0" class="count">{{this.unreadCount}}</span>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3"
               id="notificationDropdown">
            <perfect-scrollbar>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom" *ngFor="let notification of notifications">
                <i *ngIf="!notification.read" class="simple-icon-bell font-weight-bold"
                   (click)="toggleMarkNotification(notification)" style="cursor: pointer"></i>
                <div class="pl-3">
                  <a href="javascript:;" (click)="openNotificationLink(notification)">

                    <p *ngIf="notification.read" class="font-weight-medium mb-1">
                      <i class="{{getNotificationIcon(notification)}}"></i> {{notification.obj.title}}
                    </p>
                    <p *ngIf="!notification.read" class="font-weight-bold mb-1">
                      <i class="{{getNotificationIcon(notification)}}"></i> {{notification.obj.title}}
                    </p>
                    <p class="text-muted mb-0 text-small">{{notification.notif}}</p>
                  </a>
                </div>
              </div>
              <!--
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/profile-pic-l-2.jpg" alt="Notification Image"
                       class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"/>
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              -->
            </perfect-scrollbar>
          </div>
        </div>
      </div>

    </div>

    <span class="user">
      <!-- AZIENDA -->
      <div class="d-inline-block instance-menu" dropdown>
        <button class="btn btn-empty p-0" type="button" dropdownToggle (click)="openInstances()">
          <span>{{nodeName}}@{{instanceName}}</span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li role="menuitem" *ngFor="let instance of instances">
            <a class="dropdown-item" href="javascript:;" (click)="changeInstance(instance)"
               [ngClass]="{'currentInstance': isCurrentInstance(instance)}">{{instance.sLabel}}</a>
          </li>
          <hr>
          <li role="menuitem" *ngIf="rootNode != null">
            <a class="dropdown-item" href="javascript:;" (click)="changeNode(rootNode)">
              {{rootNode.sLabel}} - {{rootNode.sTooltip}}
            </a>
          </li>
          <li role="menuitem" *ngIf="parentNode != null">
            <a class="dropdown-item" href="javascript:;" (click)="changeNode(parentNode)">
              {{parentNode.sLabel}} - {{parentNode.sTooltip}}
            </a>
          </li>
          <li role="menuitem" *ngIf="currentNode != null">
            <a class="dropdown-item" href="javascript:;">
              <strong>{{currentNode.sLabel}} - {{currentNode.sTooltip}}</strong>
            </a>
          </li>
          <hr *ngIf="subNodes.length > 0">
          <li role="menuitem" *ngFor="let node of subNodes">
            <a class="dropdown-item" href="javascript:;" (click)="changeNode(node)">{{node.sLabel}}
              - {{node.sTooltip}}</a>
          </li>
        </ul>
      </div>

      <!-- PROFILO UTENTE -->
      <div class="d-inline-block" dropdown>
        <button class="btn btn-empty p-0" type="button" dropdownToggle>
          <span class="name">{{displayName}}</span>
          <span>
            <img alt="Profile Picture" src="/assets/img/profile-pic-l.jpg"/>
          </span>
        </button>

        <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
          <!-- SELETTORE LINGUA -->
          <!--
          <div class="btn-group" appClickStopPropagation dropdown *ngIf="!isSingleLang" style="width: 100%"
               #languageDropdown="bs-dropdown">
            <button dropdownToggle type="button" class="language-button dropdown-toggle"
                    style="width: 100%; padding: 0.5rem 1.25rem; text-align: left">
              {{ "menu.language" | translate }}: {{currentLanguage | uppercase}} <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a
                class="dropdown-item" href="javascript:;">{{item.label}}</a></li>
            </ul>
          </div>
          -->

          <!-- DARK MODE -->
          <!--
          <div class="dropdown-item" appClickStopPropagation>
            <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
              <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
                     (change)="onDarkModeChange($event)">
              <label class="custom-control-label" for="darkModeSwitch"></label>
            </div>
          </div>
          -->
          <a class="dropdown-item c-pointer" routerLink="/app/user-settings">{{"user.settings" | translate}}</a>

          <li class="divider dropdown-divider"></li>
          <a class="dropdown-item c-pointer" (click)="onSignOut()">{{"user.logout" | translate}}</a>
        </div>
      </div>


      <div class="d-inline-block">
        <!-- FULLSCREEN -->
        <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
                (click)="fullScreenClick()">
          <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
          <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
        </button>
      </div>
    </span>
  </div>
</nav>
