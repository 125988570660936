<div class="container-fluid p-4 inspector-container">
  <app-modal-title title="Inspector"></app-modal-title>
  <div class="row">
    <div class="col-12">
      <tabset [vertical]="true" class="vertical" [tabIndex]="tabIndex">
        <tab *ngFor="let model of models"
             heading=""
             (selectTab)="changeTab($event)"
             [id]="getTabId(model)"
             [active]="currentTabId === getTabId(model)">
          <ng-template tabHeading>
            <div class="titleHeading tab-id-{{getTabId(model)}}">
              <div class="title">
                <div>
                  <i class='{{getIcon(model)}}'></i>
                </div>
                <div>
                  {{ getTitle(model) }}
                </div>
              </div>
              <button class="btn btn-xs btn-outline-primary close-button" (click)="closeTab(model)">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </ng-template>

          <app-risk-details *ngIf="model.type == 'Risk'"
                            [risk]="model.rawData"
          ></app-risk-details>

          <app-analysis-details *ngIf="model.type == 'Analysis'"
                                [analysis]="model.rawData"
                                [isGross]="model.analysisType == 'gross'"
          ></app-analysis-details>

          <app-treatments-details *ngIf="model.type == 'Treatment'"
                                  [treatment]="model.rawData"
          ></app-treatments-details>
        </tab>
      </tabset>
    </div>
  </div>

</div>
