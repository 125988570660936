import {BasePath, BaseService, Body, GET, Headers, Path, POST, Response} from 'ts-retrofit';
import {environment} from '../../environments/environment';
import {EsgLoginPinCodeResponse, EsgLoginResponse} from '../data/riesko/responses/esg-login.response';
import {GenericResponse} from '../data/riesko/generic-response';
import {
  EsgDashboardDossier, EsgDashboardItem,
  EsgDashboardResponse,
  EsgDossierPart, EsgDossierPartAnagrafica,
  EsgDossierPartMateriality,
  EsgDossierPartMaturity,
  EsgDossierPartPresurvey, EsgDossierPartReport,
  EsgDossierPartValutation,
  EsgStruct
} from '../data/riesko/responses/esg-dashboard';
import {ACCEPT_JSON} from './base-rest-api';
import {EsgSearchRequest} from '../data/riesko/requests/esg-search-request';
import {EsgSearchResponse, EsgSupplierSearchComboStructure} from '../data/riesko/responses/esg-search-response';

@BasePath(environment.apiUrl + environment.basePrefixApiUrl)
export class EsgRestApiService extends BaseService {
  @GET('/')
  async ping(): Promise<Response> {
    return {} as Response;
  }

  @POST('/esg/bind/{supplierId}')
  @Headers(ACCEPT_JSON)
  async createEsgSupplier(@Path('supplierId') biaSupplier: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @GET('/esg')
  async getEsgSupplierList(): Promise<Response<GenericResponse<EsgDashboardResponse>>> {
    return {} as Response;
  }


  @GET('/esg/{id}/dossier')
  async getDossierByEsgSupplierId(@Path('id') id: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // lock - post: /DTA/esg/{id supplier}/lock
  @POST('/esg/{id}/lock')
  async lock(@Path('id') id: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/esg/{id}/unlock')
  async unlock(@Path('id') id: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/esg/{id}/unbind')
  async unbind(@Path('id') id: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/esg/{id}/send')
  async send(@Path('id') id: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/esg/{id}/dossier/create')
  async createDossier(@Path('id') id: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/esg/{id}/dossier/{dossierId}/drop')
  async dropDossier(@Path('id') id: string, @Path('dossierId') dossierId: number | string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/esg/{id}/send')
  async sendInviteEmail(@Path('id') id: string): Promise<Response<string>> {
    return {} as Response;
  }

  @GET('/esg/landing/{key}')
  async getLoginParameters(@Path('key') key: string): Promise<Response<GenericResponse<EsgLoginResponse>>> {
    return {} as Response;
  }

  @POST('/esg/landing/{key}/send')
  async requestLoginPinCode(@Path('key') key: string): Promise<Response<GenericResponse<EsgLoginPinCodeResponse>>> {
    return {} as Response;
  }

  @POST('/esg/landing/{key}/login')
  async sendPin(@Path('key') key: string, @Body data: { pin: string, validator: string }): Promise<Response<GenericResponse<{ access_open: boolean, success: boolean }>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/edit')
  async editEsgDossier(@Path('esgSupplierId') esgSupplierId: string,
                       @Path('esgDossierId') esgDossierId: string): Promise<Response<GenericResponse<EsgDashboardDossier>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/query_print')
  async printEsgDossier(@Path('esgSupplierId') esgSupplierId: string,
                       @Path('esgDossierId') esgDossierId: string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/open')
  async openEsgDossier(@Path('esgSupplierId') esgSupplierId: string,
                       @Path('esgDossierId') esgDossierId: string): Promise<Response<GenericResponse<EsgDashboardDossier>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/{esgPartId}')
  async openEsgPart(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPart>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/{esgPartId}/edit')
  async editEsgPart(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPart>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/PRESURVEY')
  async openEsgPartPresurvey(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartPresurvey>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/PRESURVEY/edit')
  async editEsgPartPresurvey(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartPresurvey>>> {
    return {} as Response;
  }

  @POST('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/PRESURVEY')
  async saveEsgPartPresurvey(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string,
                             @Body part: EsgDossierPartPresurvey): Promise<Response<GenericResponse<EsgDossierPartPresurvey>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/MATURITY')
  async openEsgPartMaturity(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartMaturity>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/MATURITY/edit')
  async editEsgPartMaturity(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartMaturity>>> {
    return {} as Response;
  }

  @POST('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/MATURITY')
  async saveEsgPartMaturity(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string,
                             @Body part: EsgDossierPartMaturity): Promise<Response<GenericResponse<EsgDossierPartMaturity>>> {
    return {} as Response;
  }


  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/VALUTATION')
  async openEsgPartValutation(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartValutation>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/VALUTATION/edit')
  async editEsgPartValutation(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartValutation>>> {
    return {} as Response;
  }

  @POST('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/VALUTATION')
  async saveEsgPartValutation(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string,
                            @Body part: EsgDossierPartValutation): Promise<Response<GenericResponse<EsgDossierPartValutation>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/MATERIALITY')
  async openEsgPartMateriality(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartMateriality>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/MATERIALITY/edit')
  async editEsgPartMateriality(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartMateriality>>> {
    return {} as Response;
  }

  @POST('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/MATERIALITY')
  async saveEsgPartMateriality(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string,
                               @Body part: EsgDossierPartMateriality): Promise<Response<GenericResponse<EsgDossierPartMateriality>>> {
    return {} as Response;
  }

  @POST('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/REPORT')
  async saveEsgPartReport(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string,
                               @Body part: EsgDossierPartReport): Promise<Response<GenericResponse<EsgDossierPartReport>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/REPORT')
  async openEsgPartReport(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartReport>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/REPORT/edit')
  async editEsgPartReport(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartReport>>> {
    return {} as Response;
  }


  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/ANAGRAFICA')
  async openEsgPartAnagrafica(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartAnagrafica>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/ANAGRAFICA/edit')
  async editEsgPartAnagrafica(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string): Promise<Response<GenericResponse<EsgDossierPartAnagrafica>>> {
    return {} as Response;
  }

  @POST('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/ANAGRAFICA')
  async saveEsgPartAnagrafica(@Path('esgSupplierId') esgSupplierId: string, @Path('esgDossierId') esgDossierId: string, @Path('esgPartId') esgPartId: string,
                               @Body part: EsgDossierPartAnagrafica): Promise<Response<GenericResponse<EsgDossierPartAnagrafica>>> {
    return {} as Response;
  }

  @POST('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/{esgPartId}/changestatus/{stateId}')
  async editEsgPartState(@Path('esgSupplierId') esgSupplierId: string,
                         @Path('esgDossierId') esgDossierId: string,
                         @Path('esgPartId') esgPartId: string,
                         @Path('stateId') stateId: number)
    : Promise<Response<GenericResponse<EsgDossierPart>>> {
    return {} as Response;
  }

  @GET('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/{esgPartId}/new_struct/{number}')
  async newChainStruct(@Path('esgSupplierId') supplierId: string, @Path('esgDossierId') dossierId: string, @Path('esgPartId') partId: string, @Path('number') number: number): Promise<Response<GenericResponse<EsgStruct>>> {
    return {} as Response;
  }

  @POST('/esg/{esgSupplierId}/dossier/{esgDossierId}/parts/{esgPartId}')
  async saveEsgPart(@Path('esgSupplierId') supplierId: string, @Path('esgDossierId') dossierId: string, @Path('esgPartId') partId: string, @Body data: EsgDossierPart): Promise<Response<GenericResponse<EsgDossierPart>>> {
    return {} as Response;
  }

  @GET('/esg/search')
  async searchEsgSupplierInitial(): Promise<Response<GenericResponse<EsgSupplierSearchComboStructure>>> {
    return {} as Response;
  }

  @GET('/esg/search/{level1}')
  async searchEsgSupplierLevel1(@Path('level1') level1: string): Promise<Response<GenericResponse<EsgSupplierSearchComboStructure>>> {
    return {} as Response;
  }

  @GET('/esg/search/{level1}/{level2}')
  async searchEsgSupplierLevel2(@Path('level1') level1: string,
                                @Path('level2') level2: string): Promise<Response<GenericResponse<EsgSupplierSearchComboStructure>>> {
    return {} as Response;
  }

  @POST('/esg/search')
  async searchEsgSupplier(@Body data: EsgSearchRequest): Promise<Response<GenericResponse<EsgDashboardItem[]>>> {
    return {} as Response;
  }
}
