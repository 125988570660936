import {
  GET, POST, PUT, PATCH, DELETE, HEAD, OPTIONS, BasePath, Header, Query,
  Headers, Path, Field,
  BaseService, ServiceBuilder, Response, ResponseInterceptor, Body
} from 'ts-retrofit';
import {Injectable} from '@angular/core';
import {GenericResponse} from '../data/riesko/generic-response';
import {LoginResponse} from '../data/riesko/responses/login-response';
import {
  FrameAzienda,
  FrameMenuItem,
  FrameNodeList,
  FrameNodo,
  FrameResponse,
  FrameUser
} from '../data/riesko/responses/frame-response';
import {environment} from 'src/environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {from, Observable} from 'rxjs';
import {InfoTopicMessageListResponse} from '../data/riesko/responses/info-topic-message';
import {RiskRowItemResponse} from '../data/riesko/responses/risk-row-item-response';
import {TokensService} from './tokens.service';
import {InfoTopicMessageRequest} from '../data/riesko/requests/info-topic-message-request';
import {Risk, RiskAttachment} from '../data/riesko/risk';
import {RiskFileRequest} from '../data/riesko/requests/risk-file-request';
import {ACCEPT_JSON, CONTENT_TYPE_JSON, createRestApiService} from './base-rest-api';
import {Analysis} from '../data/riesko/analysis';
import {Treatment} from '../data/riesko/treatment';
import {AnalyticRequest} from '../data/riesko/requests/analytic-request';
import {AnalyticDashboardResponse, AnalyticResponse} from '../data/riesko/responses/analytic-response';
import {RieskoErrorNotificationService} from './riesko-error-notification.service';
import {RiskActivableResponse} from '../data/riesko/responses/risk-activable-response';
import {BiaUser} from '../data/riesko/responses/bia-responses';
import {LibraryResponse} from '../data/riesko/responses/library-list-response';
import {Campaign, Issue, IssuesListResponse} from '../data/riesko/responses/issue';


@Injectable({providedIn: 'root'})
@BasePath(environment.apiUrl + environment.basePrefixApiUrl)
export class RestApiService extends BaseService {

  // ===================================================================================
  //    PING
  // ==================================================================================

  @GET('/')
  async ping(): Promise<any> {
    return {} as Response<any>;
  }

  // ===================================================================================
  //    LOGIN
  // ===================================================================================
  @POST('/login')
  @Headers({
    'content-type': 'application/x-www-form-urlencoded',
    ...ACCEPT_JSON
  })
  async doLogin(@Field('csUsrn') username: string,
                @Field('csPass') password: string): Promise<Response<GenericResponse<LoginResponse>>> {
    return {} as Response<GenericResponse<LoginResponse>>;
  }

  // ===================================================================================
  //    LOGOUT
  // ===================================================================================
  @POST('/logout')
  @Headers({'Accept': 'application/json'})
  async doLogout(): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET SESSION
  // ===================================================================================
  @GET('/session')
  @Headers({'Accept': 'application/json'})
  async getSession(): Promise<Response<GenericResponse<LoginResponse>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CHANGE INSTANCE
  // ===================================================================================
  @POST('/switch/{instance}/{idUser}')
  @Headers(ACCEPT_JSON)
  async changeInstance(
    @Path('instance') instance: string,
    @Path('idUser') idUser: number
  ): Promise<Response<GenericResponse<LoginResponse>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CHANGE NODE
  // ===================================================================================
  @POST('/change/{idNode}')
  @Headers(ACCEPT_JSON)
  async changeNode(
    @Path('idNode') idNode: number
  ): Promise<Response<GenericResponse<LoginResponse>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    GET FULL FRAME
  // ===================================================================================
  @GET('/frame')
  @Headers(ACCEPT_JSON)
  async getFrame(): Promise<Response<GenericResponse<FrameResponse>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET DASHBOARD
  // ===================================================================================
  @GET('/frame/dashboard')
  @Headers(ACCEPT_JSON)
  async getDashboardFrame(): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  manageErrors(promise: Promise<any>): Observable<any> { // TODO: rimuovere
    promise.catch();
    return from(promise);
  }

  // ===================================================================================
  //    GET MENU FRAME
  // ===================================================================================
  @GET('/frame/menu')
  @Headers(ACCEPT_JSON)
  async getFrameMenu(): Promise<Response<GenericResponse<FrameMenuItem>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET MENU FRAME
  // ===================================================================================
  @GET('/frame/user')
  @Headers(ACCEPT_JSON)
  async getFrameUser(): Promise<Response<GenericResponse<FrameUser>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET MENU FRAME INSTANCES
  // ===================================================================================
  @GET('/frame/user/instances')
  @Headers(ACCEPT_JSON)
  async getFrameUserInstances(): Promise<Response<GenericResponse<Array<FrameAzienda>>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET MENU FRAME NODES
  // ===================================================================================
  @GET('/frame/user/nodes')
  @Headers(ACCEPT_JSON)
  async getFrameUserAllNodes(): Promise<Response<GenericResponse<FrameNodeList>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET MENU FRAME NODES
  // ===================================================================================
  @GET('/frame/user/nodes/{idNode}')
  @Headers(ACCEPT_JSON)
  async getFrameUserNodes(
    @Path('idNode') idNode: number
  ): Promise<Response<GenericResponse<FrameNodo>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    FRAME NOTIFICATIONS
  // ==================================================================================
  @GET('/frame/notifications?FromId={id}')
  async getNotifications(@Path('id') id: number | null = null): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/frame/notifications/{id}/mark')
  async markNotification(@Path('id') id: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/frame/notifications/{id}/unmark')
  async unmarkNotification(@Path('id') id: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    GET INFO TOPIC - MESSAGES
  // ===================================================================================
  @GET('/frame/infoTopic')
  @Headers(ACCEPT_JSON)
  async getInfoTopic(
    @Query('filter') filter?: string,
    @Query('tipo') type?: string,
    @Query('level') level?: number,
    @Query('max_date') maxDate?: string,
    @Query('item') item?: string,
    @Query('from_id') fromId?: number,
    @Query('min_date') minDate?: string,
    @Query('beforeId') beforeId?: number,
  ): Promise<Response<GenericResponse<InfoTopicMessageListResponse>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SEND NEW MESSAGE - INFO TOPIC
  // ===================================================================================
  // TODO: chidere a WITTO perche` non funziona.
  @POST('/frame/infoTopic')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async sendInfoTopicMessage(@Body body: InfoTopicMessageRequest,
                             @Query('filter') filter?: string,
                             @Query('tipo') type?: string,
                             @Query('level') level?: number,
                             @Query('max_date') maxDate?: string,
                             @Query('item') item?: string,
                             @Query('from_id') fromId?: number,
                             @Query('min_date') minDate?: string
  ): Promise<Response<GenericResponse<InfoTopicMessageListResponse>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    RISK's TOOLTIP
  // ===================================================================================
  @GET('/risk/{itemId}/tooltip')
  @Headers(ACCEPT_JSON)
  async getRiskTooltip(@Path('itemId') itemId: number): Promise<Response<GenericResponse<Risk>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CREATE NEW RISK
  // ===================================================================================
  // @POST('/risk/create')
  @POST('/risk/create')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async createRisk(): Promise<Response<GenericResponse<Risk>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    EDIT RISK BY ITEM ID
  // ===================================================================================
  @GET('/risk/{itemId}/edit')
  @Headers(ACCEPT_JSON)
  async editRisk(@Path('itemId') itemId: number): Promise<Response<GenericResponse<Risk>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    APPLY RISK
  // ===================================================================================
  @POST('/risk/{itemId}/apply')
  @Headers(ACCEPT_JSON)
  async applyRisk(@Path('itemId') itemId: number | string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    UNAPPLY RISK
  // ===================================================================================
  @POST('/risk/{itemId}/unapply')
  @Headers(ACCEPT_JSON)
  async unapplyRisk(@Path('itemId') itemId: number | string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DROP RISK
  // ===================================================================================
  @POST('/risk/{itemId}/drop')
  @Headers(ACCEPT_JSON)
  async dropRisk(@Path('itemId') itemId: number | string): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    IS RISK ACTIVABLE
  // ===================================================================================
  @GET('/risk/{riskId}/activable')
  @Headers(ACCEPT_JSON)
  async isRiskActivable(@Path('riskId') riskId: number | string): Promise<Response<GenericResponse<RiskActivableResponse>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    GET RISKS TABLE'ROWS
  // ===================================================================================
  @GET('/risk/list/{stateId}')
  @Headers(ACCEPT_JSON)
  async getRisksList(@Path('stateId') stateId: number = 0): Promise<Response<GenericResponse<RiskRowItemResponse[][]>>> {
    return {} as Response;
  }

  @GET('/risk/riskenabled')
  @Headers(ACCEPT_JSON)
  async getRisksForTreatment(): Promise<Response<any>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET RISKS TABLE'S ROWS
  // ===================================================================================
  @GET('/treatments/treat_{filter}')
  @Headers(ACCEPT_JSON)
  async getTreatmentsList(@Path('filter') filter: string): Promise<Response<GenericResponse<RiskRowItemResponse[][]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET ANALYSIS GROSS TABLE'S ROS
  // ===================================================================================
  @GET('/analysis/gross_{filter}')
  @Headers(ACCEPT_JSON)
  async getAnalysisGrossList(@Path('filter') filter: string): Promise<Response<GenericResponse<RiskRowItemResponse[][]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET ANALYSIS NET TABLE'S ROS
  // ===================================================================================
  @GET('/analysis/net_{filter}')
  @Headers(ACCEPT_JSON)
  async getAnalysisNetList(@Path('filter') filter: string): Promise<Response<GenericResponse<RiskRowItemResponse[][]>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET ANALYSIS INFO
  // ===================================================================================
  @GET('/analysis/{type}/{id}')
  @Headers(ACCEPT_JSON)
  async getAnalysisInformation(@Path('type') type: string, @Path('id') id: string): Promise<Response<GenericResponse<Analysis>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    OPEN ANALYSIS
  // ===================================================================================
  @GET('/analysis/{type}/{id}/open')
  @Headers(ACCEPT_JSON)
  async openAnalysis(@Path('type') type: string, @Path('id') id: string): Promise<Response<GenericResponse<Analysis>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    EDIT ANALYSIS
  // ===================================================================================
  @GET('/analysis/{type}/{id}/edit')
  @Headers(ACCEPT_JSON)
  async editAnalysis(@Path('type') type: string, @Path('id') id: string): Promise<Response<GenericResponse<Analysis>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE ANALYSIS
  // ===================================================================================
  @POST('/analysis/{type}/{id}')
  @Headers(ACCEPT_JSON)
  async saveAnalysis(@Path('type') type: string, @Path('id') id: string, @Body analysis: Analysis): Promise<Response<GenericResponse<Analysis>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CHANGE ANALYSIS'S STATUS
  // ===================================================================================
  @POST('/analysis/{type}/{id}/changestatus/{stateId}')
  @Headers(ACCEPT_JSON)
  async changeAnalysisState(@Path('type') type: string, @Path('id') id: string, @Path('stateId') state: number): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //   DROP ANALYSIS
  // ===================================================================================
  @POST('/analysis/{type}/{id}/drop')
  @Headers(ACCEPT_JSON)
  async dropAnalysis(@Path('type') type: string, @Path('id') id: string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    NEW ANALYSIS FROM RISK
  // ===================================================================================
  @POST('/analysis/{type}/create/{riskId}')
  @Headers(ACCEPT_JSON)
  async newAnalysis(@Path('type') type: string, @Path('riskId') riskId: number | string): Promise<Response<GenericResponse<Analysis>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CLONE ANALYSIS FROM RISK
  // ===================================================================================
  @POST('/analysis/{type}/create/{riskId}/clone')
  @Headers(ACCEPT_JSON)
  async cloneAnalysis(@Path('type') type: string, @Path('riskId') riskId: number | string): Promise<Response<GenericResponse<Analysis>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET RISK INFO
  // ===================================================================================
  @GET('/risk/{itemId}')
  @Headers(ACCEPT_JSON)
  async getRiskInformation(@Path('itemId') itemId: number | string): Promise<Response<GenericResponse<Risk>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET ANALYSIS GROSS TABLE'S ROS
  // ===================================================================================
  @POST('/risk/{itemId}')
  @Headers(ACCEPT_JSON)
  async saveRisk(@Path('itemId') itemId: string | number, @Body risk: Risk): Promise<Response<GenericResponse<Risk>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    UPLOAD NEW FILE
  // ===================================================================================
  @POST('/risk/{itemId}/file/upload')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async uploadRiskFile(@Path('itemId') itemId: string | number, @Body riskFileRequest: RiskFileRequest): Promise<Response<GenericResponse<{ attach: RiskAttachment[] }>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    UPLOAD NEW FILE
  // ===================================================================================
  @POST('/{root}/{itemId}/file/upload')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async uploadItemFile(@Path('root') root: string,
                       @Path('itemId') itemId: string | number,
                       @Body riskFileRequest: RiskFileRequest
  ): Promise<Response<GenericResponse<{ attach: RiskAttachment[] }>>> {
    return {} as Response;
  }

  @POST('/{root}/{itemId}/file/upload/{subOwner}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async uploadItemFileWithSubOwner(@Path('root') root: string,
                       @Path('itemId') itemId: string | number,
                       @Body riskFileRequest: RiskFileRequest,
                       @Path('subOwner') subOwner: string | undefined
  ): Promise<Response<GenericResponse<{ attach: RiskAttachment[] }>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    LINK OF FILE to open in new tab
  // ===================================================================================
  @GET('/{root}/{itemId}/file/secure-open/{fileId}')
  async secureOpenFile(@Path('root') root: string, @Path('itemId') itemId: string, @Path('fileId') fileId: number): Promise<Response<GenericResponse<string>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DOWNLOAD FILE
  // ===================================================================================
  @GET('/{root}/{itemId}/file/secure-download/{fileId}')
  async secureDownloadFile(@Path('root') root: string, @Path('itemId') itemId: string, @Path('fileId') fileId: number): Promise<Response<GenericResponse<string>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DOWNLOAD ALL FILE
  // ===================================================================================
  @GET('/secure/{uploadPath}/{itemId}/file/all')
  async secureDownloadAllFile(
    @Path('uploadPath') uploadPath: string,
    @Path('itemId') itemId: string,
  ): Promise<Response<GenericResponse<string>>> {
    return {} as Response;
  }

  @GET('/secure/{uploadPath}/{itemId}/file/all/{subOwner}')
  async secureDownloadAllFileWithSubOwner(
    @Path('uploadPath') uploadPath: string,
    @Path('itemId') itemId: string,
    @Path('subOwner') subOwner: string
  ): Promise<Response<GenericResponse<string>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CHANGE RISK'S STATE
  // ===================================================================================
  @POST('/risk/{itemId}/change_status/{stateId}')
  async changeState(@Path('itemId') itemId: number, @Path('stateId') stateId: number): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }


  // ===================================================================================
  //    GET TREATMENT INFO
  // ===================================================================================
  @GET('/treatments/{id}')
  @Headers(ACCEPT_JSON)
  async getTreatmentInfo(@Path('id') id: string): Promise<Response<GenericResponse<Treatment>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    OPEN TREATMENT
  // ===================================================================================
  @GET('/treatments/{id}/open')
  @Headers(ACCEPT_JSON)
  async openTreatment(@Path('id') id: string): Promise<Response<GenericResponse<Treatment>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    EDIT TREATMENT
  // ===================================================================================
  @GET('/treatments/{id}/edit')
  @Headers(ACCEPT_JSON)
  async editTreatment(@Path('id') id: string): Promise<Response<GenericResponse<Treatment>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    SAVE TREATMENT
  // ===================================================================================
  @POST('/treatments/{id}')
  @Headers(ACCEPT_JSON)
  async saveTreatment(@Path('id') id: string, @Body treatment: Treatment): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    DROP TREATMENT
  // ===================================================================================
  @POST('/treatments/{id}/drop')
  @Headers(ACCEPT_JSON)
  async dropTreatment(@Path('id') id: string): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    CHANGE TREATMENT STATUS
  // ===================================================================================
  @POST('/treatments/{id}/change_status/{stateId}')
  @Headers(ACCEPT_JSON)
  async changeTreatmentState(@Path('id') id: string, @Path('stateId') state: number): Promise<Response<GenericResponse<boolean>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    NEW TREATMENT
  // ===================================================================================
  @POST('/treatments/create/{idRisk}')
  @Headers(ACCEPT_JSON)
  async createTreatmentFromIdRisk(@Path('idRisk') idRisk: string): Promise<Response<GenericResponse<Treatment>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET TREATMENT FOR RISK
  // ===================================================================================
  @GET('/treatments/risknode/{idRisk}')
  @Headers(ACCEPT_JSON)
  async getTreatmentFromIdRisk(@Path('idRisk') idRisk: string): Promise<Response<GenericResponse<Treatment[]>>> {
    return {} as Response;
  }

  @GET('/treatments/risknode/{idRisk}/{idAnalysis}')
  @Headers(ACCEPT_JSON)
  async getTreatmentFromIdRiskAndAnalysis(@Path('idRisk') idRisk: string, @Path('idAnalysis') idAnalysis: string): Promise<Response<GenericResponse<{ prev: Treatment[], resps: Treatment[] }>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    GET PERSON BY ID
  // ===================================================================================
  @GET('/treatments/users/{search}')
  @Headers(ACCEPT_JSON)
  async searchTreatmentUsers(@Path('search') search: string): Promise<Response<GenericResponse<BiaUser[]>>> {
    return {} as Response;
  }

  @POST('/analitic')
  @Headers(ACCEPT_JSON)
  async getAnalyticData(@Body analyticRequest: AnalyticRequest): Promise<Response<GenericResponse<AnalyticResponse>>> {
    return {} as Response;
  }

  @GET('/analitic/dashboard')
  @Headers(ACCEPT_JSON)
  async getAnalyticDashboard(): Promise<Response<GenericResponse<AnalyticDashboardResponse[]>>> {
    return {} as Response;
  }

  @GET('/library')
  @Headers(ACCEPT_JSON)
  async getLibraries(): Promise<Response<GenericResponse<LibraryResponse[]>>> {
    return {} as Response;
  }

  @POST('/library/import')
  @Headers(ACCEPT_JSON)
  async importLibrary(@Body input: { id: number | null, risks: { id: number }[] }): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  // ===================================================================================
  //    ISSUES
  // ===================================================================================

  @GET('/survey/campaign')
  @Headers(ACCEPT_JSON)
  async getCampaigns(): Promise<Response<GenericResponse<IssuesListResponse>>> {
    return {} as Response;
  }

  @POST('/survey/campaign/create')
  @Headers(ACCEPT_JSON)
  async createCampaign(): Promise<Response<GenericResponse<Issue>>> {
    return {} as Response;
  }

  @GET('/survey/campaign/{id}/open')
  @Headers(ACCEPT_JSON)
  async openCampaign(@Path('id') id: number): Promise<Response<GenericResponse<Campaign>>> {
    return {} as Response;
  }

  @GET('/survey/campaign/{id}/edit')
  @Headers(ACCEPT_JSON)
  async editCampaign(@Path('id') id: number): Promise<Response<GenericResponse<Campaign>>> {
    return {} as Response;
  }

  @POST('/survey/issues/{id}/drop')
  @Headers(ACCEPT_JSON)
  async deleteIssue(@Path('id') id: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }

  @POST('/survey/campaign/{id}')
  @Headers(ACCEPT_JSON)
  async saveCampaign(@Path('id') id: number, @Body campaign: Campaign): Promise<Response<GenericResponse<Issue>>> {
    return {} as Response;
  }

  @POST('/survey/issues/{id}')
  @Headers(ACCEPT_JSON)
  async saveIssue(@Path('id') id: number, @Body issue: Issue): Promise<Response<GenericResponse<Issue>>> {
    return {} as Response;
  }


  @POST('/survey/campaign/{id}/changestatus/{stateId}')
  @Headers(ACCEPT_JSON)
  async changeIssueState(@Path('id') id: number, @Path('stateId') state: number): Promise<Response<GenericResponse<Issue>>> {
    return {} as Response;
  }

  @POST('/survey/campaign/{id}/append')
  @Headers(ACCEPT_JSON)
  async addIssueToCampaign(@Path('id') id: number): Promise<Response<GenericResponse<any>>> {
    return {} as Response;
  }
}

// ===================================================================================
//    FACTORY FUNCTION
// ===================================================================================
export function createRieskoRestApiService(translate: TranslateService,
                                           storage: StorageService,
                                           router: Router,
                                           crsfService: TokensService,
                                           rieskoErrorNotificationService: RieskoErrorNotificationService) {
  return createRestApiService(translate, storage, router, crsfService, rieskoErrorNotificationService, RestApiService);
}
