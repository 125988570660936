import {ErrorHandler} from '@angular/core';
import {NotificationsService, NotificationType} from 'angular2-notifications';
import {RestApiError} from './base-rest-api';
import {environment} from '../../environments/environment';


export class RieskoExceptionHandler implements ErrorHandler {
  constructor(private notifications: NotificationsService) {
  }

  handleError(error: any): void {
    if (error.rejection !== undefined && error.rejection instanceof RestApiError) {
      console.log('errore gestito');
      console.log(error); // todo: print da rimuovere

      if (environment.showErrors) {
        this.notifications.create('Errore', error.rejection.message, NotificationType.Error, {
          theClass: 'outline primary',
          timeOut: 6000,
          showProgressBar: false
        });
      }
      return;
    }
    // errore che potrebbe essere stato gestito altrove
    // alert(error); // todo: alert da rimuovere
    console.log('il seguente errore potrebbe essere stato comunque gestito altrove: ');
    console.log(error); // todo: creare logger degli errori non gestiti?
  }
}
