import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {from, Observable} from 'rxjs';
import * as ɵngcc0 from '@angular/core';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Configs} from '../../configs';

@Injectable({ providedIn: 'root' })
export class RieskoAuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}

  canActivate() {
    if (!this.auth.isLoggedIn()) {
      return this.router.navigateByUrl(Configs.loginPath);
    }
    return true;
  }
}

@Injectable({ providedIn: 'root' })
export class EsgRieskoAuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}

  canActivate() {
    if (!this.auth.isEsgLoggedIn()) {
      return this.router.navigateByUrl(Configs.loginPath);
    }
    return true;
  }
}
