import {Injectable} from '@angular/core';
import {Md5} from 'ts-md5';
import {StorageService} from './storage.service';

@Injectable({providedIn: 'root'})
export class TokensService {
  private _crsfSeed: string = null;
  private _jwt: string = null;

  constructor(private storage: StorageService) {
  }

  public setCrsfSeed(seed: string) {
    this._crsfSeed = seed;
  }

  public hasCrsfSeed() {
    return this._crsfSeed != null;
  }

  public getCrsfToken(method: string) {
    return Md5.hashStr(`${this._crsfSeed}${method}`);
  }

  public setJwt(token: string) {
    this.storage.token = token;
    this._jwt = token;
    console.log({jwt: this._jwt});
  }

  public hasJwt(): boolean {
    this._jwt = this.storage.token;
    return this._jwt != null;
  }

  public getJwt(): string {
    this._jwt = this.storage.token;
    return this._jwt;
  }
}
