import {EsgLoginResponse, LoginResponse} from '../data/riesko/responses/login-response';
import {Router} from '@angular/router';

const STORE_LOGIN_DATA = 'store_login_data';
const STORE_ESG_LOGIN_DATA = 'store_esg_login_data';
const STORE_SCREENSHOT_URL = 'store_screenshot_url';
const STORE_SCREENSHOT_URL_TIMELIFE = 'store_screenshot_url_timelife';
const STORE_STICKY_RIGT_BAR = 'store_sticky_right_bar';
const STORE_RISK_IDENTIFICATION_BTN_INDEX = 'store_risk_identification_index';
const STORE_RISK_IDENTIFICATION_SEARCH_KEY = 'store_risk_identification_search';

const STORE_ANALYSIS_NET_BTN_INDEX = 'store_analysis_index_net';
const STORE_ANALYSIS_NET_SEARCH_KEY = 'store_analysis_search_net';
const STORE_ANALYSIS_GROSS_BTN_INDEX = 'store_analysis_index_gross';
const STORE_ANALYSIS_GROSS_SEARCH_KEY = 'store_analysis_search_gross';

const STORE_TREATMENT_BTN_INDEX = 'store_treatment_index';
const STORE_TREATMENT_SEARCH_KEY = 'store_treatment_search';

const STORE_BIA_BTN_INDEX = 'store_bia_index';
const STORE_BIA_BTN_SEARCH_KEY = 'store_bia_search';
const STORE_BIA_BNT_INDEX_LEVEL = 'store_bia_index_level';

const STORAGE_TOKEN = 'storage_token';

export class StorageService {
  // ===================================================================================
  //    PROPS
  // ===================================================================================
  private _loginData: LoginResponse = null;
  private _esgLoginData: EsgLoginResponse = null;
  private _screenshotUrl: string = null;
  private _stickyRightBar: boolean = null;
  private _riskIdentificationBtnIndex: string = null;
  private _riskIdentificationSearchKey: string = null;

  private _analysisNetBtnIndex: string = null;
  private _analysisNetSearchKey: string = null;
  private _analysisGrossBtnIndex: string = null;
  private _analysisGrossSearchKey: string = null;

  private _treatmentBtnIndex: string = null;
  private _treatmentSearchKey: string = null;

  private _biaStateFilterIndex: string = null;
  private _biaBtnIndexLevel: string = null;
  private _biaSearchKey: string = null;
  private _token: string = null;

  // ===================================================================================
  //    CONSTRUCTOR
  // ===================================================================================
  constructor(private router: Router) {
  }

  // ===================================================================================
  //    STATIC METHODS
  // ===================================================================================

  static _remove(key: string) {
    localStorage.removeItem(key);
  }

  static _clear() {
    localStorage.clear();
  }

  static _save(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static _load(key: string): string {
    return localStorage.getItem(key);
  }

  static _loadData<T>(key: string): T {
    const value: string = StorageService._load(key);
    if (value == null || value.trim().length === 0) {
      return null;
    }
    return JSON.parse(value) as T;
  }


  static _saveData<T>(key: string, value: T) {
    if (value === null) {
      StorageService._remove(key);
    } else {
      StorageService._save(key, JSON.stringify(value));
    }
  }

  // ===================================================================================
  //    METHODS
  // ===================================================================================

  doUrlScreenshot() {
    if (!this.router.url.endsWith('/login')) {
      this.screenshotUrl = this.router.url;
    } else {
      this.screenshotUrl = null;
    }
    this.loginData = null;
    this.router.navigate(['/']);
  }

  get stickyRightBar(): boolean {
    if (this._stickyRightBar == null) {
      this._stickyRightBar = StorageService._loadData(STORE_STICKY_RIGT_BAR);
    }
    return this._stickyRightBar ?? false;
  }

  set stickyRightBar(sticky: boolean) {
    this._stickyRightBar = sticky;
    StorageService._saveData(STORE_STICKY_RIGT_BAR, this._stickyRightBar);
  }

  get loginData(): LoginResponse {
    if (this._loginData == null) {
      this._loginData = StorageService._loadData(STORE_LOGIN_DATA);
    }
    return this._loginData;
  }

  set loginData(loginData: LoginResponse) {
    this._loginData = loginData;
    StorageService._saveData(STORE_LOGIN_DATA, this._loginData);
  }

  get esgLoginData(): EsgLoginResponse {
    if (this._esgLoginData == null) {
      this._esgLoginData = StorageService._loadData(STORE_ESG_LOGIN_DATA);
    }
    return this._esgLoginData;
  }

  set esgLoginData(esgLoginData: EsgLoginResponse) {
    this._esgLoginData = esgLoginData;
    StorageService._saveData(STORE_ESG_LOGIN_DATA, this._esgLoginData);
  }

  get screenshotUrl(): string {
    if (this._screenshotUrl == null) {
      const timeLife: Date = StorageService._loadData(STORE_SCREENSHOT_URL_TIMELIFE);
      const now = new Date();
      if (timeLife != null && now <= timeLife) {
        this._screenshotUrl = StorageService._loadData(STORE_SCREENSHOT_URL);
      }
      StorageService._remove(STORE_SCREENSHOT_URL_TIMELIFE);
      StorageService._remove(STORE_SCREENSHOT_URL);
    }
    return this._screenshotUrl;
  }

  set screenshotUrl(url: string) {
    this._screenshotUrl = url;
    StorageService._saveData(STORE_SCREENSHOT_URL, url);
    const timeLife = new Date();
    timeLife.setHours(timeLife.getHours() + 1);
    StorageService._saveData(STORE_SCREENSHOT_URL_TIMELIFE, timeLife.toJSON());
  }

  get riskIdentificationBtnIndex(): string {
    if (this._riskIdentificationBtnIndex == null) {
      this._riskIdentificationBtnIndex = StorageService._load(STORE_RISK_IDENTIFICATION_BTN_INDEX);
    }
    return this._riskIdentificationBtnIndex;
  }

  set riskIdentificationBtnIndex(value: string) {
    this._riskIdentificationBtnIndex = value;
    StorageService._save(STORE_RISK_IDENTIFICATION_BTN_INDEX, value);
  }

  get riskIdentificationSearchText(): string {
    if (this._riskIdentificationSearchKey == null) {
      this._riskIdentificationSearchKey = StorageService._load(STORE_RISK_IDENTIFICATION_SEARCH_KEY);
    }
    return this._riskIdentificationSearchKey;
  }

  set riskIdentificationSearchText(value: string) {
    this._riskIdentificationSearchKey = value;
    StorageService._save(STORE_RISK_IDENTIFICATION_SEARCH_KEY, value);
  }


  get anaylsisNetBtnIndex(): string {
    if (this._analysisNetBtnIndex == null) {
      this._analysisNetBtnIndex = StorageService._load(STORE_ANALYSIS_NET_BTN_INDEX);
    }
    return this._analysisNetBtnIndex;
  }

  set anaylsisNetBtnIndex(value: string) {
    this._analysisNetBtnIndex = value;
    StorageService._save(STORE_ANALYSIS_NET_BTN_INDEX, value);
  }

  get anaylsisNetSearchText(): string {
    if (this._analysisNetSearchKey == null) {
      this._analysisNetSearchKey = StorageService._load(STORE_ANALYSIS_NET_SEARCH_KEY);
    }
    return this._analysisNetSearchKey;
  }

  set anaylsisNetSearchText(value: string) {
    this._analysisNetSearchKey = value;
    StorageService._save(STORE_ANALYSIS_NET_SEARCH_KEY, value);
  }



  get anaylsisGrossBtnIndex(): string {
    if (this._analysisGrossBtnIndex == null) {
      this._analysisGrossBtnIndex = StorageService._load(STORE_ANALYSIS_GROSS_BTN_INDEX);
    }
    return this._analysisGrossBtnIndex;
  }

  set anaylsisGrossBtnIndex(value: string) {
    this._analysisGrossBtnIndex = value;
    StorageService._save(STORE_ANALYSIS_GROSS_BTN_INDEX, value);
  }

  get anaylsisGrossSearchText(): string {
    if (this._analysisGrossSearchKey == null) {
      this._analysisGrossSearchKey = StorageService._load(STORE_ANALYSIS_GROSS_SEARCH_KEY);
    }
    return this._analysisGrossSearchKey;
  }

  set anaylsisGrossSearchText(value: string) {
    this._analysisGrossSearchKey = value;
    StorageService._save(STORE_ANALYSIS_GROSS_SEARCH_KEY, value);
  }


  get treatmentBtnIndex(): string {
    if (this._treatmentBtnIndex == null) {
      this._treatmentBtnIndex = StorageService._load(STORE_TREATMENT_BTN_INDEX);
    }
    return this._treatmentBtnIndex;
  }

  set treatmentBtnIndex(value: string) {
    this._treatmentBtnIndex = value;
    StorageService._save(STORE_TREATMENT_BTN_INDEX, value);
  }

  get treatmentSearchText(): string {
    if (this._treatmentSearchKey == null) {
      this._treatmentSearchKey = StorageService._load(STORE_TREATMENT_SEARCH_KEY);
    }
    return this._treatmentSearchKey;
  }

  set treatmentSearchText(value: string) {
    this._treatmentSearchKey = value;
    StorageService._save(STORE_TREATMENT_SEARCH_KEY, value);
  }


  get biaStateFilterIndex(): string {
    if (this._biaStateFilterIndex == null) {
      this._biaStateFilterIndex = StorageService._load(STORE_BIA_BTN_INDEX);
    }
    return this._biaStateFilterIndex;
  }

  set biaStateFilterIndex(value: string) {
    this._biaStateFilterIndex = value;
    StorageService._save(STORE_BIA_BTN_INDEX, value);
  }
  get biaBtnIndexLevel(): string {
    if (this._biaBtnIndexLevel == null) {
      this._biaBtnIndexLevel = StorageService._load(STORE_BIA_BNT_INDEX_LEVEL);
    }
    return this._biaBtnIndexLevel;
  }

  set biaBtnIndexLevel(value: string) {
    this._biaBtnIndexLevel = value;
    StorageService._save(STORE_BIA_BNT_INDEX_LEVEL, value);
  }

  get biaSearchText(): string {
    if (this._biaSearchKey == null) {
      this._biaSearchKey = StorageService._load(STORE_BIA_BTN_SEARCH_KEY);
    }
    return this._biaSearchKey;
  }

  set biaSearchText(value: string) {
    this._biaSearchKey = value;
    StorageService._save(STORE_BIA_BTN_SEARCH_KEY, value);
  }

  get token(): string {
    if (this._token == null) {
      this._token = StorageService._load(STORAGE_TOKEN);
    }
    return this._token;
  }

  set token(value: string) {
    this._token = value;
    StorageService._save(STORAGE_TOKEN, value);
  }

  // ===================================================================================
  //    PRIVATE METHODS
  // ===================================================================================

  // ===================================================================================
  //    HELPER METHODS
  // ===================================================================================
}
