import {Injectable} from '@angular/core';
import {EsgRestApiService} from './esg-rest.api.service';
import {ResponseInterceptor, ServiceBuilder} from 'ts-retrofit';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {TokensService} from './tokens.service';
import {RieskoErrorNotificationService} from './riesko-error-notification.service';
import {GenericResponse} from '../data/riesko/generic-response';
import {RestApiError} from './base-rest-api';
import {BiaRestApiService} from './bia-rest-api.service';
import {RestApiService} from './rest-api.service';
import {UserRestApiService} from './user-rest-api.service';
import {AdminApiService} from './api/admin.api.service';

const CRSF_TOKEN = 'CSRF_Token';

@Injectable({providedIn: 'root'})
export class AllRestApiService {
  constructor(private translate: TranslateService,
              private storage: StorageService,
              private router: Router,
              private tokensService: TokensService,
              private rieskoErrorNotificationService: RieskoErrorNotificationService) {
    this.esg = serviceFactory(EsgRestApiService, this.translate, this.storage, this.router, this.tokensService, this.rieskoErrorNotificationService);
    this.bia = serviceFactory(BiaRestApiService, this.translate, this.storage, this.router, this.tokensService, this.rieskoErrorNotificationService);
    this.riesko = serviceFactory(RestApiService, this.translate, this.storage, this.router, this.tokensService, this.rieskoErrorNotificationService);
    this.user = serviceFactory(UserRestApiService, this.translate, this.storage, this.router, this.tokensService, this.rieskoErrorNotificationService);
    this.admin = serviceFactory(AdminApiService, this.translate, this.storage, this.router, this.tokensService, this.rieskoErrorNotificationService);
  }

  esg: EsgRestApiService;
  bia: BiaRestApiService;
  riesko: RestApiService;
  user: UserRestApiService;
  admin: AdminApiService;
}


export const serviceFactory = <T>(service: new (builder: ServiceBuilder, ...args: any[]) => T,
                                  translate: TranslateService,
                                  storage: StorageService,
                                  router: Router,
                                  tokensService: TokensService,
                                  rieskoErrorNotificationService: RieskoErrorNotificationService) => {
  const responseInterceptor = new RieskoResponseInterceptor(translate, storage, router, tokensService, rieskoErrorNotificationService);

  return new ServiceBuilder()
    // .setEndpoint(environment.apiUrl + environment.basePrefixApiUrl)
    .setStandalone(true)
    .setRequestInterceptors((request) => {
      const url = request.url.substr((environment.apiUrl + environment.basePrefixApiUrl).length);
      if (!isInIgnoreCrsfList(url)) {
        if (tokensService.hasCrsfSeed()) {
          request.headers[CRSF_TOKEN] = tokensService.getCrsfToken(url);
        }
        if (tokensService.hasJwt()) {
          request.headers['Authentication'] = tokensService.getJwt();
        }

      }
      if (request.data != null) {
        /*const entities = new Entities();
        const str = JSON.stringify(request.data);
        request.data = entities.encode(str);
a
        /*
        const stack = [];
        for (const prop in request.data) {
          if (prop instanceof Object) {
            stack.push(prop);
          }

          if (prop instanceof String) {
            prop = entities.encode(prop);
          }
        }
        */
        // TODO: chiedere a WITTO. Possiamo iterare su tutte le props dell'oggetto e applicare la encode in auto oppure gestirla a manina prima delle chiamate
        // PRO/CONTRO: auto e` piu` veloce da implementare e da gestire. Tendenzialmente meno bug.
        // request.data = entities.encode(request.data);
      }
      return request;
    })
    .setResponseInterceptors(responseInterceptor)
    .build(service);
};


class RieskoResponseInterceptor extends ResponseInterceptor<any> {

  constructor(private translate: TranslateService,
              private storage: StorageService,
              private router: Router,
              private tokensService: TokensService,
              private rieskoErrorNotificationService: RieskoErrorNotificationService) {
    super();
  }

  onFulfilled(r) {
    const json = JSON.stringify(r);

    if (r.data != null) {
      const dataResponse = r.data as GenericResponse<any>;
      if (dataResponse != null && dataResponse.bSuccess === false) {
        // errore rilevato
        let exceptionTxt;
        if (dataResponse.iError === -1) {
          if (dataResponse.sError != null && dataResponse.sError.length > 0) {
            exceptionTxt = dataResponse.sError;
          }
        }
        if (dataResponse.iError > 0) {
          const translationKey = `errors.${dataResponse.iError}`;
          exceptionTxt = this.translate.instant(translationKey);
          if (exceptionTxt === translationKey) {
            // traduzione non implementata: riporto il messaggio di errore riportato
            if (dataResponse.sError != null && dataResponse.sError.length > 0) {
              exceptionTxt = dataResponse.sError + ` (code: ${dataResponse.iError})`;
            }
          }
        }

        if (exceptionTxt == null || exceptionTxt.length === 0) {
          exceptionTxt = this.translate.instant(`errors.generic`);
        }
        try {
          if (dataResponse.iError !== 3) {
            const exceptionStack = dataResponse.stack?.join('\n')?.replace('\t', '');
            this.rieskoErrorNotificationService.emitError.emit({title: exceptionTxt, body: exceptionStack});
          }
        } catch (ex) {

        }
        throw Error(exceptionTxt);
      }
      if (dataResponse?.CSRF_Seed != null) {
        this.tokensService.setCrsfSeed(dataResponse.CSRF_Seed);
      }
      if (r.headers.authentication) {
        const token = r.headers.authentication;
        this.tokensService.setJwt(token);
      }
    }
    return r;
  }

  onRejected(error: any) {
    const checkErrorCode = (code: string) => error.message.includes(code);
    if (checkErrorCode('401')) {
      console.log('errore 401 rilevato');
      this.storage.doUrlScreenshot();
    }
    throw new RestApiError('Non sei autorizzato ad accedere alla risorsa, ricollegati'); // TODO: traduzione del messaggio
    // return error;
  }

}


const ignoreList = ['/login'];

function isInIgnoreCrsfList(apiUrl: string) {
  return ignoreList.includes(apiUrl);
}
