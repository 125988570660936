import {Component} from '@angular/core';
import {HeadingAction, HeadingService} from '../../../shared/heading.service';

@Component({
  selector: 'app-heading-actions',
  templateUrl: './heading-actions.component.html',
  styleUrls: ['./heading-actions.component.css'],
})
export class HeadingActionsComponent {
  actions: HeadingAction[] = [];

  constructor(private headingService: HeadingService) {
    this.headingService.onChangeHeadingActions.subscribe((actions: HeadingAction[]) => {
      this.actions = actions;
    });
  }

  getClasses(action: HeadingAction) {
    if (action.primary) {
      return 'btn btn-primary btn-shadow';
    }
    return 'btn btn-outline-primary';
  }
}
