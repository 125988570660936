<ng-template [ngIf]="hasAttachments" [ngIfElse]="noAttachmentsTemplate">
  <table class="table table-striped table-hover">
    <tr>
      <th>{{'attachments.document'|translate}}</th>
      <th>{{'attachments.user'|translate}}</th>
      <th>{{'attachments.date-time'|translate}}</th>
      <th>{{'attachments.attachment'|translate}}
        <a *ngIf="hasAttachments" class="btn btn-sm btn-primary" (click)="secureDownloadAllFiles()"
           target="_blank">
          <span class="glyph-icon simple-icon-cloud-download"></span>
        </a>
      </th>
    </tr>
    <tr *ngFor="let attach of getAttachments()">
      <td>{{attach.sNam}}</td>
      <td><span [innerHTML]="attach.user.sName"></span></td>
      <td>{{attach.sData}}</td>
      <td>
        <app-risk-file [fileId]="attach.id" [riskItemId]="attachContainerId" [rootPath]="uploadPath"
                       [filename]="getFilenameWithExt(attach)"></app-risk-file>
      </td>
    </tr>
  </table>
</ng-template>
<ng-template #noAttachmentsTemplate>
  <p>{{'attachments.no-attachment'|translate}}</p>
</ng-template>

<div class="box-input" *ngIf="canEdit">
  <ngx-dropzone (change)="onSelect($event)">
    <ngx-dropzone-label>{{'attachments.upload'|translate}}</ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of uploadingFiles" [removable]="false" (removed)="onRemove(f)" >
      <ngx-dropzone-label>
        <app-risk-file [filename]="f.name" [onlyPreview]="true" [rootPath]="uploadPath"></app-risk-file>
      </ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
</div>
