import {Component} from '@angular/core';
import {Router, Event, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {HeadingBreadItem, HeadingService} from '../../../shared/heading.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})

export class BreadcrumbComponent {
  path = '';
  pathArr: string[] = [];
  pathDecode: HeadingBreadItem [] = [];
  currentRoute = '';
  useCustomTitle = false;
  customTitle: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public headingService: HeadingService, private translateService: TranslateService) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      ).subscribe((event) => {
      this.generateBreadcrumb(event);
    });

    this.headingService.onChangeCustomTitle.subscribe((value: string | null) => {
      this.useCustomTitle = value != null;
      this.customTitle = value;
    });

    this.headingService.onChangeSubTitleResolver.subscribe((value: (title: string, index: number) => HeadingBreadItem) => {
      this.pathDecode = []; // force breadcrumb update
      this.generateBreadcrumb(this.activatedRoute);
    });

  }

  private generateBreadcrumb(event: ActivatedRoute) {
    this.path = this.router.url.slice(1, this.router.url.split('?')[0].length);
    const paramtersLen = Object.keys(event.snapshot.params).length;
    this.pathArr = this.path.split('/').slice(0, this.path.split('/').length - paramtersLen);
    this.currentRoute = this.pathArr[this.pathArr.length - 1];
    let i = 0;
    this.pathArr.forEach(pt => {
      try {
        const c = i++;
        const headingBreadItem: HeadingBreadItem|HeadingBreadItem[]  = this.headingService.subTitleResolver('menu.' + pt, c);
        if (Array.isArray(headingBreadItem)) {
          for (const item of headingBreadItem) {
            if (!item.link) {
              item.link = this.getUrl(pt);
            }
            this.pathDecode.push(item);
          }
        } else if (headingBreadItem as HeadingBreadItem) {
          if (!headingBreadItem.link && !headingBreadItem.action) {
            headingBreadItem.link = this.getUrl(pt);
          }
          this.pathDecode.push(headingBreadItem);
        } else {
          // teoricamente non dovrebbe mai arrivare qui
          const item: HeadingBreadItem = {label: pt, link: this.getUrl(pt), visible: pt !== ''};
          this.pathDecode.push(item);
        }
      } catch (ex) {
        const item: HeadingBreadItem = {label: '', link: '', visible: false};
        this.pathDecode.push(item);
      }

      // translate the labels:
      this.pathDecode.forEach((item, index) => {
        const translatedLabel = item.label ? this.translateService.instant(item.label) : '';
        if (typeof translatedLabel === 'string') {
          item.label = translatedLabel;
        }
      });
    });
  }

  getUrl = (sub: string) => {
    return '/' + this.path.split(sub)[0] + sub;
  }
}
