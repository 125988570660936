<div class="menu" (click)="menuClicked($event)">
  <div class="main-menu">
    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
      <ul class="list-unstyled">
        <li *ngFor="let item of menuItems" [class.active]="isInPath(item)">

          <ng-template [ngIf]="isActive(item)">
            <a (click)="apriSottoMenu()" style="cursor: pointer">
              <i [ngClass]="item.icon"></i> {{item.label | trim | translate}}
            </a>
          </ng-template>

          <ng-template [ngIf]="!isActive(item)">
            <a *ngIf="item.newWindow" [href]="item.to" rel="noopener noreferrer" target="_blank">
              <i [ngClass]="item.icon"></i>{{item.label | trim | translate}}</a>
            <a *ngIf="!item.newWindow && item.subs &&  item.subs.length>0"
               (click)="changeSelectedParentHasNoSubmenu(item.id); false"
               [routerLink]="item.to">
              <i [ngClass]="item.icon"></i> {{item.label | trim | translate}}</a>
            <a *ngIf="!item.newWindow && (!item.subs || (item.subs &&  item.subs.length===0))"
               (click)="changeSelectedParentHasNoSubmenu(item.id); false" [routerLink]="item.to">
              <i [ngClass]="item.icon"></i> {{item.label | trim | translate}}</a>
          </ng-template>
        </li>
      </ul>
    </perfect-scrollbar>
  </div>
  <div class="sub-menu">
    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
      <ul class="list-unstyled" *ngFor="let item of menuItems" [attr.data-parent]="item.id"
          [ngClass]="{'d-block': isActive(item)}">
        <li *ngFor="let sub of item.subs" class="active"
            [ngClass]="{'active': currentUrl === sub.to ,'has-sub-item' : sub.subs&& sub.subs.length>0 }">
          <a *ngIf="sub.newWindow" [href]="sub.to" rel="noopener noreferrer" target="_blank">
            <i [ngClass]="sub.icon"></i>
            <span class="d-inline-block">{{sub.label | translate}}</span>
          </a>
          <a *ngIf="!sub.newWindow && (!sub.subs || sub.subs.length<=0)" [routerLink]="[sub.to]"
             routerLinkActive="active">
            <i [ngClass]="sub.icon"></i>
            <span class="d-inline-block">{{sub.label | translate}}</span>
          </a>
          <a (click)="collapseSub.toggle()" *ngIf="sub.subs && sub.subs.length > 0"
             class="rotate-arrow-icon opacity-50 c-pointer">
            <i class="simple-icon-arrow-down"></i>
            <span class="d-inline-block">{{sub.label | translate}}</span>
          </a>
          <div [collapse]="false" #collapseSub="bs-collapse" [isAnimated]="true">
            <div *ngIf="sub.subs && sub.subs.length > 0">
              <ul class="list-unstyled inner-level-menu">
                <li *ngFor="let deepSub of sub.subs" [ngClass]="{'active': currentUrl === deepSub.to}">
                  <a *ngIf="deepSub.newWindow" [href]="deepSub.to" rel="noopener noreferrer"
                     target="_blank">
                    <i [ngClass]="deepSub.icon"></i>
                    <span class="d-inline-block">{{deepSub.label | translate}}</span>
                  </a>
                  <a *ngIf="!deepSub.newWindow && (!deepSub.subs || deepSub.subs.length<=0)"
                     [routerLink]="[deepSub.to]" routerLinkActive="active">
                    <i [ngClass]="deepSub.icon"></i>
                    <span class="d-inline-block">{{deepSub.label | translate}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
      </ul>
    </perfect-scrollbar>
  </div>
</div>
