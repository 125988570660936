<div class="app-menu"
     [hidden]="!visible"
     [ngClass]="{'closed': closed}"
     (mouseleave)="closeMenu()"
     (mouseenter)="openMenu()"
>
  <div class="h-100 scrollbar-container">
    <div class="workgroup-header">
      WORKGROUP
      <button type="button" class="btn btn-primary toggle-menu" [(ngModel)]="pinnedBar" btnCheckbox
              [btnCheckboxTrue]="true"
              [btnCheckboxFalse]="false" (click)="onChangePinnedBar()">
        <span class="glyph-icon simple-icon-pin"></span>
      </button>
    </div>
    <perfect-scrollbar class="ignore-right-space" #scrollbar>
      <div class="message-spacer"></div>
      <div class="load-more separator-time" (click)="loadMore()">
        <div class="btn">Load more</div>
      </div>
      <div *ngFor="let message of messages">
        <div class="separator-time" *ngIf="checkSeparatorTime(message)">
          <div class="line"></div>
          <div class="day">{{getLastDay()}}</div>
        </div>
        <div class="message" [class.message-std]="message.body?.type !== 'upload'" (click)="openInspector(message)">
          <div class="message-badge" [ngClass]="message.type"></div>
          <div class="content">
            <div class="object-area">
              <span *ngIf="message.oObject?.code != null">{{message.oObject.code}}</span>
              <img class="profile" src="/assets/img/profile-pic-l.jpg"> <!-- TODO: aggiornare avatar -->
              <span class="time">{{message.tstamp | toTime}}</span>
            </div>
            <div>
              <div class="username">{{message.oUser?.sName}}</div>
              <div class="title" [innerHTML]="message.title"></div>
            </div>
            <div *ngIf="message.body?.type == 'text' || message.body?.type == 'span'" [ngClass]="message.body.cls">
              <span class="text-icon glyph-icon simple-icon-check" *ngIf="isSuccessClass(message.body.cls)"></span>
              <span class="text-icon glyph-icon simple-icon-exclamation"
                    *ngIf="isFailureClass(message.body.cls)"></span>
              <span [innerHTML]="message.body?.body?.msg"></span>
            </div>
            <div *ngIf="message.body?.type == 'upload'">
              <app-risk-file [fileId]="message.body.body.ID"
                             [rootPath]="getRootPath()"
                             [filename]="message.body.body.sFilename"
                             [riskItemId]="getRiskItemId(message)"
                             [customWidth]="180"></app-risk-file>
            </div>
          </div>
        </div>
      </div>
      <div class="message-spacer"></div>
    </perfect-scrollbar>
    <div class="message-sender" *ngIf="canSendMessage">
      <div class="input-group mb-3">
        <input type="text" class="form-control" [placeholder]="'chat.new-message' | translate"
               [(ngModel)]="chatMessage">
        <div class="input-group-append">
          <button type="button" class="btn btn-outline-secondary" (click)="sendNewChatMessage()">
            <span class="glyph-icon iconsminds-mail-send"></span>
          </button>
        </div>
      </div>

      <!--
      <input type="text" class="form-control" placeholder="Digita un messaggio da inviare">
      <button class="btn btn-primary">
        <span class="glyph-icon iconsminds-mail-send"></span>
      </button>
      -->
    </div>
  </div>
</div>
