import {Component, Input} from '@angular/core';
import {NodeSelectorService} from '../../containers/layout/topnav/node-selector.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Treatment} from '../../data/riesko/treatment';
import {Translations} from '../../services/translations.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-treatments-details',
  templateUrl: './treatments-details.component.html'
})
export class TreatmentsDetailsComponent {

  @Input()
  treatment: Treatment;

  constructor(public translations: Translations,
              private nodeSelector: NodeSelectorService,
              private router: Router,
              private modal: BsModalRef) {
  }

  getRiskValutatedAtTabTitle(): string {
    return this.nodeSelector.currentNode.sLabel;
  }

  close(): void {
    this.modal.hide();
  }

  open() {
    this.router.navigateByUrl('/app/treatment/' + this.treatment.sItem);
    this.modal.hide();
  }
}

