import {BiaProcessLevel} from '../data/riesko/responses/bia-responses';
import {TranslateService} from '@ngx-translate/core';
import {Translations} from './translations.service';
import {Injectable} from '@angular/core';

@Injectable()
export class TipoBiaService {

  constructor(private translate: TranslateService, private translations: Translations) {
  }

  public transform(value: number, isService?: boolean): string {
    switch (value) {
      case BiaProcessLevel.SERVICE:
        if (isService !== undefined) {
          if (isService) {
            return this.translate.instant(this.translations.bia['general-tab'].servizio);
          }
          return this.translate.instant(this.translations.bia['general-tab'].prodotto);
        }
        return this.translate.instant(this.translations.bia['general-tab'].servizio);
      case BiaProcessLevel.SUBPROCESS:
        return this.translate.instant(this.translations.bia.subProcess);
      case BiaProcessLevel.PROCESS:
        return this.translate.instant(this.translations.bia.process);
      case BiaProcessLevel.ACTIVITY:
        return this.translate.instant(this.translations.bia.activity);
      default:
        return value.toString();
    }
  }
}
