import {Component, Input} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RestApiService} from '../../shared/rest-api.service';

@Component({
  selector: 'app-risk-file',
  templateUrl: './risk-file.component.html',
  styleUrls: ['./risk-file.component.css']
})
export class RiskFileComponent {

  @Input()
  fileId: number;

  @Input()
  onlyPreview = false;

  @Input()
  filename: string;

  @Input()
  extension: string;

  @Input()
  riskItemId: string;

  @Input()
  customWidth?: number;

  @Input()
  rootPath?: string = 'risk';

  @Input()
  disableDownload = false;

  constructor(private api: RestApiService) {
  }

  private static getFileExtension(filename: string): string {
    if (filename != null && filename.includes('.')) {
      return filename.substr(filename.lastIndexOf('.') + 1);
    }
    return 'unk';
  }

  openDownloadLinkFile() {
    if (this.disableDownload) {
      return;
    }
    // const linkToOpen = `${environment.apiUrl}/AttachAPI?fileid=${this.fileId}`;
    const linkToOpen = `${environment.apiUrl}${environment.basePrefixApiUrl}/${this.rootPath}/${this.riskItemId}/file/download/${this.fileId}`;
    window.open(linkToOpen, '_blank');
  }

  // openInlineLinkFile() {
  //   return `${environment.apiUrl}${environment.basePrefixApiUrl}/${this.rootPath}/${this.riskItemId}/file/open/${this.fileId}`;
  // }

  async openSecureFile() {
    const response = await this.api.secureOpenFile(this.rootPath, this.riskItemId, this.fileId);
    const link: string = response.data.oData;

    if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      window.open(`${environment.apiUrl}${link}`, '_blank');
    }
  }

  async downloadSecureFile() {
    const response = await this.api.secureDownloadFile(this.rootPath, this.riskItemId, this.fileId);
    const link: string = response.data.oData;

    if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      window.open(`${environment.apiUrl}${link}`, '_blank');
    }
  }

  getIconFileType(extension: string = this.extension) {
    if (extension == null) {
      extension = RiskFileComponent.getFileExtension(this.filename);
    }
    return `/assets/img/riesko/filetype/${extension}.png`;
  }

  getIconFileTypeOnError(tag: HTMLImageElement) {
    tag.src = this.getIconFileType('unk');
  }

  getCustomStyle() {
    if (this.customWidth != null) {
      return `width: ${this.customWidth}px`;
    }
  }
}
