import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutoSelect]'
})
export class SelectOnFocusDirective {
  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    input.select();
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    console.log('click');
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    input.select();
  }
}
