import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {TokensService} from './tokens.service';
import {ACCEPT_JSON, CONTENT_TYPE_JSON, createRestApiService} from './base-rest-api';
import {RestApiService} from './rest-api.service';
import {BasePath, BaseService, Body, GET, Header, Headers, Path, POST, Response} from 'ts-retrofit';
import {GenericResponse} from '../data/riesko/generic-response';
import {LoginResponse} from '../data/riesko/responses/login-response';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {RieskoErrorNotificationService} from './riesko-error-notification.service';
import {BiaCutOff} from '../data/riesko/responses/bia-responses';
import {User} from '../data/riesko/user';

@Injectable({providedIn: 'root'})
@BasePath(environment.apiUrl + environment.basePrefixApiUrl)
export class UserRestApiService extends BaseService {
  @GET('/frame/user/{id}')
  @Headers(ACCEPT_JSON)
  async getUser(@Path('id') id: string = '0'): Promise<Response<GenericResponse<User>>> {
    return {} as Response;
  }

  @POST('/frame/user/{id}')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveUser(@Path('id') id: string, @Body user: User): Promise<Response<GenericResponse<User>>> {
    return {} as Response;
  }

  // TODO: da verificare, dipende come vuole che venga passata l'immagine vittorio...
  @POST('/frame/user/{id}/avatar')
  @Headers({...ACCEPT_JSON, ...CONTENT_TYPE_JSON})
  async saveAvatarUser(@Path('id') id: string, @Body base64Image: string): Promise<Response<GenericResponse<User>>> {
    return {} as Response;
  }
}

// ===================================================================================
//    FACTORY FUNCTION
// ===================================================================================

export function createUserRestApiService(translate: TranslateService,
                                         storage: StorageService,
                                         router: Router,
                                         crsfService: TokensService,
                                         rieskoErrorNotificationService: RieskoErrorNotificationService) {
  return createRestApiService(translate, storage, router, crsfService, rieskoErrorNotificationService, UserRestApiService);
}
