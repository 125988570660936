import {Component, OnInit, OnDestroy, HostListener, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {SidebarService, ISidebar} from '../sidebar/sidebar.service';
import {Router} from '@angular/router';
import {LangService, Language} from 'src/app/shared/lang.service';
import {AuthService} from 'src/app/shared/auth.service';
import {environment} from 'src/environments/environment';
import {RestApiService} from '../../../shared/rest-api.service';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {NgForm} from '@angular/forms';
import {FrameAzienda, FrameNodeList, FrameNodo} from '../../../data/riesko/responses/frame-response';
import {NotificationsService, NotificationType} from 'angular2-notifications';
import {NodeSelectorService} from './node-selector.service';
import {TokensService} from '../../../shared/tokens.service';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {defineLocale, deLocale, itLocale} from 'ngx-bootstrap/chronos';
import {MessagesService, NotificationData} from '../rightbar/messages.service';
import {takeUntil, windowTime} from 'rxjs/operators';
import {InfoTopicNotification} from '../../../data/riesko/responses/info-topic-message';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: [
    './topnav.component.scss'
  ]
})
export class TopnavComponent implements OnInit, OnDestroy {
  // ====================================================================
  //      FIELDS
  // ====================================================================
  sidebar: ISidebar;
  subscription: Subscription;
  subscriptionNotifications: Subscription;

  displayName = this.authService.currentUser.sFullname;
  nodeName = this.authService.currentLocation.sNode;
  instanceName = this.authService.currentLocation.sAzienda;

  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  instances: FrameAzienda[];
  notifications: InfoTopicNotification[] = [];
  unreadCount = 0;
  lastRequestId = 0;

  @ViewChild('languageDropdown') public languageDropdown: BsDropdownDirective;

  // ====================================================================
  //      CONSTRUCTOR
  // ====================================================================

  constructor(private sidebarService: SidebarService,
              private authService: AuthService,
              private router: Router,
              private langService: LangService,
              private api: RestApiService,
              private crsf: TokensService,
              private localeService: BsLocaleService,
              private messagesService: MessagesService,
              // private notifications: NotificationsService,
              private nodeSelectorService: NodeSelectorService) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = this.getColor().indexOf('dark') > -1 ? true : false;
  }

  ngOnInit() {
    defineLocale('de', deLocale);
    this.localeService.use('de');


    this.subscriptionNotifications = this.messagesService.onNotificationsChanges.subscribe(
      (data: NotificationData) => {
        if (data.requestId > this.lastRequestId) {
          this.notifications = data.notifications ?? [];
          this.unreadCount = this.notifications.length;
          this.lastRequestId = data.requestId;
        }
      }
    );

    this.subscription = this.sidebarService.getSidebar().subscribe(
      res => {
        this.sidebar = res;
      },
      err => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionNotifications.unsubscribe();
  }


  // ====================================================================
  //      GET & SET
  // ====================================================================

  get rootNode(): FrameNodo {
    return this.nodeSelectorService.rootNode;
  }

  get currentNode(): FrameNodo {
    return this.nodeSelectorService.currentNode;
  }

  get parentNode(): FrameNodo {
    return this.nodeSelectorService.parentNode;
  }

  get subNodes(): FrameNodo[] {
    return this.nodeSelectorService.subNodes;
  }

  getColor() {
    return localStorage.getItem(environment.themeColorStorageKey)
      ? localStorage.getItem(environment.themeColorStorageKey)
      : environment.defaultColor;
  }

  // ====================================================================
  //      METHODS
  // ====================================================================

  onDarkModeChange(event) {
    let color = this.getColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    localStorage.setItem(environment.themeColorStorageKey, color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }


  fullScreenClick() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  onLanguageChange(lang) {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
    defineLocale('it', itLocale);
    this.localeService.use('it');
    this.languageDropdown.hide();
  }

  isCurrentInstance(instance: FrameAzienda) {
    return instance.IDAzienda === this.authService.currentIDAzienda;
  }

  menuButtonClick = (e: { stopPropagation: () => void; }, menuClickCount: number, containerClassnames: string) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  };

  mobileMenuButtonClick = (event: { stopPropagation: () => void; }, containerClassnames: string) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  };

  onSignOut() {
    this.authService.signOut().subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  searchKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event) {
    event.stopPropagation();
  }

  searchClick(event) {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search() {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate(['/app/pages/miscellaneous/search'], {queryParams: {key: this.searchKey.toLowerCase().trim()}});
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event) {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }

  async openInstances() {
    // TODO: pre-caricabile quando viene caricato anche il resto...
    const responseInstances = await this.api.getFrameUserInstances();
    if (responseInstances.data?.oData != null) {
      this.instances = responseInstances.data.oData;
    }
    // const responseNodes = await this.api.getFrameUserAllNodes();
    // const nodeList: FrameNodeList = responseNodes.data.oData;
    // console.log(this.nodeSelectorService.rootNode?.sLabel);
  }

  async changeInstance(instance: FrameAzienda) {
    const sInstance = instance.sInstance;
    const idUser = instance.IDUser;
    console.log('cambio istanza: ' + sInstance + ' ' + idUser.toString());
    const response = await this.api.changeInstance(sInstance, idUser);
    if (response.data?.oData != null) {
      this.authService.updateLoginData(response.data.oData, true);
    }
  }

  async changeNode(node: FrameNodo) {
    // TODO: codice replicato in start.component.ts
    const response = await this.api.changeNode(node.IDNodo);
    if (response.data?.oData != null) {
      this.authService.updateLoginData(response.data.oData, false);
      this.router.navigateByUrl(this.router.url);
    }
  }

  async test() {
    const response = await this.api.getNotifications();
    console.log({notifications: response.data.oData});
  }

  countUnread() {
    let unread = 0;
    this.notifications.forEach(item => {
      if (!item.read) {
        unread++;
      }
    });
    return unread;
  }

  async openNotificationLink(notification: InfoTopicNotification) { // SRK
    // segna la notifica come letta
    await this.api.markNotification(notification.id);
    // esegue l'azione
    switch (notification.obj.sItemType) {
      case 'RSK':
        window.location.href = '/app/identification/' + notification.obj.ID;
        return;
      case 'TRT':
        window.location.href = '/app/treatment/' + notification.obj.ID;
        return;
      case 'ANA':
        if (notification.obj.isGross) {
          window.location.href = '/app/analysis/gross/' + notification.obj.ID;
        } else {
          window.location.href = '/app/analysis/net/' + notification.obj.ID;
        }
        return;
    }
  }

  getNotificationIcon(notification: InfoTopicNotification) {
    switch (notification.obj.sItemType) {
      case 'RSK':
        return 'iconsminds-cloud-weather';
      case 'TRT':
        return 'iconsminds-umbrella-2';
      case 'ANA':
        if (notification.obj.isGross) {
          return 'iconsminds-flask';
        } else {
          return 'iconsminds-summer';
        }

    }
  }

  async toggleMarkNotification(notification: InfoTopicNotification) {
    notification.read = !notification.read;
    if (notification.read) {
      await this.api.markNotification(notification.id);
    } else {
      await this.api.unmarkNotification(notification.id);
    }
  }
}
