<div class="container-fluid p-4" *ngIf="analysis">
  <!-- Title -->
  <div class="row">
    <div class="col-10">
      <h3><i [class.iconsminds-summer]="!isGross" [class.iconsminds-flask]="isGross"></i> {{ analysis.code }}</h3>
    </div>
    <div class="col-2 text-right">
      <button class="btn btn-outline-primary btn-xs" (click)="open()">
        <!-- eye icon -->
        <i class="fa fa-eye"></i>
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="row">
    <div class="col-12">
      <tabset *ngIf="analysis">
        <tab [heading]="'analysis.general-info'|translate">
          <app-analysis-general-info [analysis]="analysis" [isGross]="isGross"></app-analysis-general-info>
        </tab>
        <tab [heading]="'analysis.cause-effects'|translate">
          <app-analysis-causes-effects-info [analysis]="analysis"></app-analysis-causes-effects-info>
        </tab>
        <tab [heading]="'risks.popup-attachments-list'|translate">
          <app-risk-attachments-info [attachContainer]="analysis" uploadPath="analysis/net"
                                     *ngIf="!analysis.isGross"></app-risk-attachments-info>
          <app-risk-attachments-info [attachContainer]="analysis" uploadPath="analysis/gross"
                                     *ngIf="analysis.isGross"></app-risk-attachments-info>
        </tab>
        <tab [heading]="('risks.popup-evaluated-in' | translate ) + ' ' + getRiskValutatedAtTabTitle()">
          <app-risk-valutated-info [risk]="analysis.risk"></app-risk-valutated-info>
        </tab>
        <tab [heading]="'risks.popup-links-list'|translate">
          <app-analysis-links-information [analysis]="analysis"></app-analysis-links-information>
        </tab>
      </tabset>
    </div>
  </div>

</div>
