<div class="d-none d-sm-block d-lg-inline-block" style="float: right">
  <div class="btn-group flex-wrap">

    <ng-container *ngFor="let action of actions">
      <button [class]="getClasses(action)" (click)="action.action()" *ngIf="!action.dropdownItems">
        {{action.label}}
      </button>

      <button dropdown *ngIf="action.dropdownItems" [class]="getClasses(action)" dropdownToggle>
        <span>{{ action.label }}</span>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li role="menuitem" *ngFor="let item of action.dropdownItems" (click)="item.action()">
            <a class="dropdown-item">{{item.label}}</a>
          </li>
        </ul>
      </button>


    </ng-container>
  </div>
</div>
