import {EventEmitter, Injectable} from '@angular/core';
import {FrameNodeList, FrameNodo} from '../../../data/riesko/responses/frame-response';
import {Observable} from 'rxjs';
import {InfoTopicMessage} from '../../../data/riesko/responses/info-topic-message';

@Injectable({
  providedIn: 'root'
})
export class NodeSelectorService {
  private _rootNode: FrameNodo = null;
  private _parentNode: FrameNodo = null;
  private _subNodes: FrameNodo[] = [];
  private _currentNode: FrameNodo = null;
  private _eventOnChangeNode = new EventEmitter<FrameNodo>();

  constructor() {
  }

  get rootNode(): FrameNodo {
    return this._rootNode;
  }

  get subNodes(): FrameNodo[] {
    return this._subNodes;
  }

  get parentNode(): FrameNodo {
    return this._parentNode;
  }

  get currentNode(): FrameNodo {
    return this._currentNode;
  }

  onCurrentNodeChange(): EventEmitter<FrameNodo> {
    return this._eventOnChangeNode;
  }

  setCurrentNode(node: FrameNodo) {
    if (node && (!this._currentNode || this._currentNode?.IDNodo !== node?.IDNodo)) {
      this._currentNode = node;
      this._rootNode = node.oTop;
      this._subNodes = node.oSubs;
      this._parentNode = node.oParent;
      this._eventOnChangeNode.emit(node);
    }
  }

  clear() {
    this._currentNode = null;
    this._rootNode = null;
    this._subNodes = [];
    this._parentNode = null;
    this._eventOnChangeNode.emit(null);
  }
}
