<nav class="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
  <ol class="breadcrumb pt-0">
    <li class="breadcrumb-item" *ngFor="let sub of pathDecode; let i=index" [hidden]="i >= (pathArr.length-1) || !sub.visible">
      <ng-template [ngIf]="sub.visible">
        <ng-template [ngIf]="sub.clickable || sub.clickable === undefined" [ngIfElse]="notClickable">
          <a [routerLink]="sub.link" *ngIf="sub.link" [innerHTML]="sub.label"></a>
          <a style="cursor: pointer" (click)="sub.action()" *ngIf="sub.action" [innerHTML]="sub.label"></a>
        </ng-template>
        <ng-template #notClickable>
          <span [innerHTML]="sub.label"></span>
        </ng-template>
      </ng-template>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      <span *ngIf="!useCustomTitle">{{ 'menu.' + currentRoute | translate }}</span>
      <span *ngIf="useCustomTitle" [innerHTML]="customTitle"></span>
    </li>
  </ol>
</nav>
